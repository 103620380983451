
$img404: url('../../img/404.svg')
$img404Dark: url('../../img/404-dark.svg')

.not-found
  background-color: #F2F2F2
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  text-align: center
  width: 100%
  height: calc(100vh - 72px)
  // & :first-child
  &::after
    content: $img404 $img404Dark !important
    position: absolute
    width: 0
    height: 0
    overflow: hidden
    z-index: -1

  .NotFound
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    text-align: center

  .img-wrapper
    padding-right: 40px !important
    border-right: 1px solid #000
    .not-found-img
      background-image: $img404
      background-size: cover
      -webkit-background-size: cover
      -moz-background-size: cover
      -o-background-size: cover
      width: 200px
      height: 134px

  .text-container
    display: flex
    flex-direction: column
    justify-content: flex-start
    text-align: start
    padding-left: 40px
    font-family: Roboto
    font-style: normal
    // font-weight: normal
    font-size: 14px
    // line-height: 24px
    p
      font-size: 14px
      // line-height: 24px
      color: #000000

    a
      // font-weight: 500
      font-size: 14px
      // line-height: 24px
      color: #3D1D75

  // .main-content
  //   width: 800px
  //   height: 680px
  //   background: #FFFFFF
  //   box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1)
  //   border-radius: 6px
  //   display: flex
  //   justify-content: center
  //   align-items: center
  //   flex-wrap: wrap
  //   flex-direction: column
  //   position: relative

  //   .NotFound
  //     /* min-height: calc(90vh - 81px);
  //     text-align: center
  //     display: flex
  //     justify-content: center
  //     align-items: center
  //     align-content: center
  //     margin: 0 auto
  //     h3
  //       font-size: 30px !important

.dark

  .not-found,.NotFound
    background-color: #131313 !important

    .not-found-img
      background-image: $img404Dark
    h3
      color: #f2f2f2

  .img-wrapper
    border-right: 1px solid #686868

  .text-container
    p
      color: #686868
    a
      color: #BDBDBD !important

.GoogleAuthError
  display: flex
  justify-content: center
  align-items: center
  &.unautetificated
    height: 100%
  .button-container
    width: 352px
    margin: 32px auto 0 auto
    // margin-top: 32px
    display: flex
    justify-content: center
    align-items: center
    .big-button
      width: 100% 
  .error-message-container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
  p
    line-height: 24px
    text-align: center
  h2
    margin: 32px auto 32px auto
  // .content-box
  //   position: relative
  //   top: 64px
  .slack-connection-wrapper
    margin-top: 0 //24px
    padding: 64px 24px !important
    width: 576px
    // height: 232px
    background: #FFFFFF
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1)
    border-radius: 6px
    display: flex
    position: relative

    /* justify-content: center;
    align-items: center
    flex-direction: column

  .login-opts-divider
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    margin-top: 22px
    margin-bottom: 24px
    color: #D8D8D8

    hr
      width: 45%
      height: 1px
      border: 0
      border-top: 1px solid #D8D8D8
  .slack-btn
    margin: 0
    background: #fff !important
    border: #503483 1px solid !important
    span
      color: #503483
    &:hover
      background: #000 !important
      span
        color: #fff
  input
    border: #503483 1px solid !important
  .slack-btn, input
    width: 352px !important
    height: 40px
    display: flex !important
    justify-content: center !important
    align-items: center !important
    text-align: center !important
  .copy-button
    position: absolute
    right: 109px
    bottom: 112px
    height: 40px
    background: #503483 !important
    color: #fff !important
    border-radius: 6px
    &:hover
      background: #000 !important
    & .disabled
      background: #A395BD !important
  .accentuated
    font-size: 16px !important
    font-weight: 500 !important

.dark
  .GoogleAuthError
    .slack-connection-wrapper
      background: #222222 !important
      color: #bdbdbd !important
    span,h5
      color: #bdbdbd !important
    .button
      background: #BDBDBD !important
      color: #333333 !important
      &:hover
          background: #f2f2f2 !important
      & .disabled
          background: #686868 !important
    hr
      background-color: #4F4F4F
    .slack-btn
      border: 2px solid #BDBDBD !important
      background: #222222 !important
      &:hover
        border-color: #f2f2f2 !important
        & span
          color: #333333 !important
      span
        color: #BDBDBD !important
      //////////////
// @media screen and (max-width: 900px)
//   .FailedSlackAuthPage
//     .item-text
//       float: left
//       width: unset
//     .ConnectSlack-wrapper .ConnectSlack-item button
//       width: unset

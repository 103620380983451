$googleChatBtn: url('../../img/google-chat-btn.svg')
$googleMeetBtn: url('../../img/google-meet-btn.svg')
$googleWorkspaceBtn: url('../../img/google-workspace-btn.svg')
$mattermostBtn: url('../../img/mattermost-btn.svg')
$microsoftTeamsBtn: url('../../img/microsoft-teams-btn.svg')
$office365Btn: url('../../img/office-365-btn.svg')
$otherBtn: url('../../img/other-btn.svg')
$serverBtn: url('../../img/server-btn.svg')
$signalBtn: url('../../img/signal-btn.svg')
$skypeBtn: url('../../img/skype-btn.svg')
$slackBtn: url('../../img/slack-btn.svg')
$telegramBtn: url('../../img/telegram-btn.svg')
$whatsAppBtn: url('../../img/whats-app-btn.svg')
$zoomBtn: url('../../img/zoom-btn.svg')

body
	&::after
		content: $googleChatBtn $googleMeetBtn $googleWorkspaceBtn $mattermostBtn 	$microsoftTeamsBtn $office365Btn $otherBtn $serverBtn $signalBtn $skypeBtn 	$slackBtn	$telegramBtn $whatsAppBtn $zoomBtn !important
		position: absolute
		width: 0
		height: 0
		overflow: hidden
		z-index: -1

.SignUp
	background-color: #F2F2F2
	display: flex
	justify-content: center
	align-items: center
	width: 100%
	height: 100vh
	flex-direction: column
	.login-opts-divider
		display: flex
		flex-direction: row
		justify-content: center
		align-items: center //flex-end
		margin-top: 22px
		margin-bottom: 24px
		color: #D8D8D8
	.additional-login-options
		margin: 32px 0 32px 0 !important
	.phone-error
		top: 166px !important
	.questionnaire-container
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		width: 100%
		hr
			width: 592px
			height: 1px
			// background-color: #D8D8D8 !important
			border: none
			height: 1px
			border-top: 1px solid #D8D8D8
			margin: 2px 0 14px 0
		p
			font-size: 12px !important
		p:first-of-type
			margin-bottom: 38px !important
	.service-items-container
		width: calc(100% - 48px)
		display: flex
		flex-direction: row
		flex-wrap: wrap
		justify-content: space-evenly
		align-items: center
		text-align: center
		margin: 0 auto
	.service-item
		min-width: 76px
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		text-align: center
		// margin: 14px 5px
		margin: 14px 0
		font-family: Roboto
		font-style: normal
		font-weight: normal
		font-size: 12px
		line-height: 12px
		color: #3D1D75
		cursor: pointer
		pointer-events: all
		div
			opacity: 0.3
			margin-bottom: 4px
			pointer-events: none

	.service-item
		&.active
			color: #000
			div
				opacity: 1 !important

		.slask,.google-chat,.telegram,.microsoft-teams,.zoom,.skype,.mattermost,.google-meet,
		.whatsapp,.signal,.google-workspace,.office-365,.own-server,.other-service
			background-size: cover
			-webkit-background-size: cover
			-moz-background-size: cover
			-o-background-size: cover
		.slask
			background-image: $slackBtn
			width: 24px
			height: 24px
		.google-chat
			background-image: $googleChatBtn
			width: 24px
			height: 24px
		.telegram
			background-image: $telegramBtn
			width: 24px
			height: 24px
		.microsoft-teams
			background-image: $microsoftTeamsBtn
			width: 24px
			height: 24px
		.zoom
			background-image: $zoomBtn
			width: 24px
			height: 24px
		.skype
			background-image: $skypeBtn
			width: 24px
			height: 24px
		.mattermost
			background-image: $mattermostBtn
			width: 24px
			height: 24px
		.google-meet
			background-image: $googleMeetBtn
			width: 24px
			height: 24px
		.whatsapp
			background-image: $whatsAppBtn
			width: 28.73px
			height: 24px
		.signal
			background-image: $signalBtn
			width: 22.92px
			height: 24px
		.google-workspace
			background-image: $googleWorkspaceBtn
			width: 99px
			height: 13.5px
			margin: 8px 0
		.office-365
			background-image: $office365Btn
			width: 20.25px
			height: 24px
		.own-server
			background-image: $serverBtn
			width: 24px
			height: 24px
		.other-service
			background-image: $otherBtn
			width: 24px
			height: 24px
	.logo-big-container
		width: 132px !important
		height: 32px !important
	.main-content
		width: 640px
		max-width: 640px
		min-width: 360px
		height: 608px
		background: #FFFFFF
		box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1)
		border-radius: 6px
		display: flex
		justify-content: center
		align-items: center
		flex-wrap: wrap
		flex-direction: column
		position: relative
		h5
			text-align: center
			font-family: Roboto
			font-style: normal
			font-weight: 500
			font-size: 24px !important
			line-height: 24px !important
			margin-bottom: 32px
			color: #000000

	.signup-link-container
		position: absolute
		top: 24px
		right: 24px
		font-size: 12px
		line-height: 16px
		// margin-right: 8px
		& span
			font-size: 12px
			line-height: 16px
			margin-right: 8px !important

	.sign-in-container
		top: 64px
		position: absolute

	.main-registration-form
		position: absolute
		top: 128px
		min-width: 352px

	.big-button
		width: 352px
		background: #503483 !important
		border-radius: 6px !important
		display: flex !important
		justify-content: center
		align-items: center
		text-align: center
		&:hover
			background: #000 !important
		& .disabled
			background: #A395BD !important

		img
			margin-right: 10px

		span
			color: #fff

	.SignUp-opts-divider
		display: flex
		flex-direction: row
		justify-content: center
		align-items: flex-end
		margin-top: 22px
		margin-bottom: 24px
		color: #D8D8D8

		hr
			width: 45%
			height: 1px
			border: 0
			border-top: 1px solid #D8D8D8

	.pass-recover
		float: right
	.login-link
		font-size: 12px
		line-height: 16px

	.pwrd-icon, .pwrd-icon-hidden
		position: absolute
		top: 7px
		right: 7px

	.cancel-container
		position: absolute
		left: 24px
		top: 21px
		font-family: Roboto
		font-style: normal
		font-weight: normal
		font-size: 12px
		line-height: 16px
		cursor: pointer
		/* identical to box height, or 133% */
		color: #3D1D75
	.policy-wrapper
		position: relative
	.privacy-policy-container
		position: absolute
		top: -1px !important
		left: 92px !important
		display: flex !important
		flex-wrap: nowrap !important
		flex-direction: row !important
	// .additional-container
	// 	display: flex
	// 	flex-direction: column
	// 	justify-content: center
	// 	align-items: center
	.rounds-container
		// position: absolute
		// bottom: -30px
		display: flex
		flex-direction: row
		align-items: center
		margin: 24px 0 0 0
		cursor: pointer
		.round
			width: 6px
			height: 6px
			border: 1.5px solid #9A8AB6
			border-radius: 50%
			margin: 0 5px
			&.active,&:hover
				background: #000000
				border: 1.5px solid #000000
	// .mail-to-container
	// 	// position: absolute
	// 	// bottom: -54px
	// 	display: flex
	// 	flex-direction: row
	// 	align-items: center
	// 	margin: 24px 0 10px 0
	// 	cursor: pointer
	// 	font-family: Roboto
	// 	font-style: normal
	// 	font-weight: normal
	// 	font-size: 14px
	// 	line-height: 24px
	.checkbox
		margin-bottom: 36px !important
		label
			font-weight: normal !important
			font-size: 14px
			// line-height: 24px
			color: #000000

	.button-container
		margin-top: 32px //26px
//password strenght bar
.PasswordStrengthBar
	top: 102px !important
	width: 96% !important
	position: absolute !important
	left: 2% !important
	p
		display: none !important

// DARK
.dark
	// .ui.selection.visible.dropdown>.text:not(.default)
	// 	color: 	#BDBDBD// #f2f2f2
	.SignUp
		background-color: #131313
		.service-item
			color: #686868
			&.active
				color: #f2f2f2
		.questionnaire-container
			// background-color: #D8D8D8 !important

			hr
				border-top: 1px solid #4F4F4F !important
			p
				color: #bdbdbd

		.big-button
			// width: 352px
			// background: #505050
			background: #BDBDBD !important
			&:hover
				background: #f2f2f2 !important
			& .disabled
				background: #686868 !important

		.signup-link-container span
			color: #F2F2F2 !important

		.main-content
			background: #222222
			box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1)
			h5
				color: #F2F2F2 !important
			span
				color: #333333

		.SignUp-opts-divider
			display: flex
			flex-direction: row
			justify-content: center
			align-items: flex-end
			margin-top: 22px
			margin-bottom: 24px
			span
				color: #4f4f4f
			hr
				width: 45%
				height: 1px
				border: 0
				border-top: 1px solid #4f4f4f

		.rounds-container
			.round
				border: 1.5px solid #686868
				&.active,&:hover
					background: #F2F2F2
					border: 1.5px solid #F2F2F2
		.mail-to-container
			p
				color: #686868
@media screen and  (max-width: 767px)
	.SignUp
		.main-content
			width: 100%
			max-width: 640px
			min-width: 360px
		.service-item
			margin: 4px 0
			font-size: 10px
		.additional-container
			p,a
				font-size: 12px !important

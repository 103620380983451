$calendar: url('../../img/calendar.svg')
$calendarSelected: url('../../img/calendar-selected.svg')
$calendarDisabled: url('../../img/calendar-disabled.svg')
$calendarDark: url('../../img/calendar-dark.svg')
$calendarSelectedDark: url('../../img/calendar-selected-dark.svg')
$calendarDisabledDark: url('../../img/calendar-disabled-dark.svg')

$search: url('../../img/search.svg')
$searchSelected: url('../../img/search-active.svg')
$searchDark: url('../../img/search-dark.svg')
$searchSelectedDark: url('../../img/search-active-dark.svg')

.ForcePage
  height: calc(100vh - 66px)
  background: #F2F2F2
  overflow: hidden
  &::after
    content: $calendar $calendarSelected $calendarDisabled $calendarDark $calendarSelectedDark $calendarDisabledDark $searchSelected $searchSelected $searchDark $searchSelectedDark// $ $
    position: absolute
    width: 0
    height: 0
    overflow: hidden
    z-index: -1
  .force-wrapper
    background: #F2F2F2
  .search-container
    // background-image: $search //$searchSelected $searchSelected $searchDark $searchSelectedDark
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 24px
    height: 24px
    position: absolute
    top: 8px
    right: 8px
    // left: 4px 
    z-index: 25 !important
    pointer-events: none
    background-color: #fff !important
    &:hover, &.selected
      // background-image: $searchSelected
    // svg
    //   path
        // stroke-width: 1px !important
        // fill: none !important    
        // stroke: none
        // stroke-width: 0
        // fill: 0

  .dash-search
    border: none !important
    border-radius: 6px
    z-index: 22 !important
    width: 100%
    i.icon
      color: #3D1D75
      opacity: 1 !important
    input
      border-radius: 6px
      border: none !important
  .search-input-holder
    position: relative
    padding: 0
    margin: 0
  .dashbord-search-container
    border-radius: 6px
    z-index: 22
    background-color: #fff
    max-height: 50vh
    overflow-y: auto
    // padding: 8px !important
    // margin-top: 
    .user-holder
      display: flex
      text-align: start
      flex-direction: row
      // border: 1px solid #d8d8d8 //#4F4F4F
      padding: 0 8px
      margin: 0 0 8px 0 !important
      border-left: none !important
      border-right: none !important
      cursor: pointer
      position: relative
      justify-content: start
      align-items: center
      text-align: center
      color: #3D1D75
      height: 40px
      .user-avatar
        width: 24px !important
        height: 24px !important
        border-radius: 50% !important
        margin-right: 8px
      p
        text-align: start
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        width: 100%
        font-size: 14px
        line-height: 16px
      &:hover
        background-color: #E2DDEA
        p
          color: #000
      &:first-of-type
        margin-top: 8px !important
      // &:last-of-type
      //   margin-bottom: 8 !important
  .react-datepicker
    border: 1px solid #222222
    z-index: 1
  .react-datepicker__header,.react-datepicker,.react-datepicker__current-month
    background-color: #fff
    color: #000
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name
    &:hover
      background-color: #333333
      color: #fff
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range
    background-color: #000
    color: #fff
    &:hover
      background-color: #333333
      color: #000
  .react-datepicker__input-container
    display: none !important
  .datePicker-container
    // pointer-events: none !important
    position: absolute !important
    top: 40px
    right: 0
    z-index: 20
  .opened
    display: block
  .closed
    display: none
  .demo-dash
    position: absolute
    top: 0
    // left: 16px
    border: none !important
    border-radius: 8px !important
    transition: 0.5s
    // &.right-sidebar
    //   top: 47px
    a
      display: flex
      flex-direction: row
      .connect-container
        margin: 0 8px
    .demo-dash-container
      background: #fff
      flex-wrap: nowrap
      display: flex
      flex-direction: column
      justify-content: center
      text-align: left
      border-radius: 6px
      width: 292px !important
      &.bottom-borderless
        // border-radius: 6px 6px 0 0 !important
        border-radius: 6px !important
    .demo-header
      padding-left: 8px //padding: 8px 0 0 10px
      margin: 0 !important
      font-weight: 500
      font-size: 14px
      line-height: 24px
      color: #000000
      text-transform: none
    a
      font-size: 14px
      line-height: 24px
    .demo-dash-content
      font-size: 14px !important
      display: flex
      justify-content: left
      flex-direction: column
      padding: 0 8px 8px 8px
      .description
        .list-item
          display: flex
          flex-direction: row
          margin: 16px 0
          // text-align: center !important
          line-height: 24px
          vertical-align: middle !important
          p
            line-height: 24px
          .list-unit
            margin-right: 8px
            display: flex
            justify-content: center
            align-items: center
            text-align: center
            background: #000 !important
            color: #fff
            vertical-align: middle
            line-height: 24px
            border-radius: 50% !important
            width: 24px !important
            height: 24px !important
          // a
          //   white-space: nowrap !important

  .search-pannel
    z-index: 24 !important
    background-color: #fff
    position: absolute
    top: 0
    left: 0//16px
    border: none !important
    border-radius: 8px !important
    transition: 0.5s
    width: 220px !important
    min-height: 40px !important
    &.border-bottom-none
      border-radius: 6px 6px 0 0 !important
    input
      border-radius: 6px
      height: 40px !important
      padding: 8px !important
      visibility: visible
    &.short
      width: 40px !important
      i.icon
        width: 40px !important
      .ui.icon.input>input
        padding-right: 0 !important
      input
        padding: 0 !important
        width: 0 !important
        caret-color: transparent
        visibility: hidden
      input::placeholder
        display: none !important
        opacity: 0 !important
    .no-result
      text-align: start
      color: #666666
      pointer-events: none
      padding: 8px
      font-family: Roboto
      font-style: normal
      font-weight: normal
      font-size: 14px
      line-height: 16px
      display: flex
      align-items: center
  .react-datepicker-popper
    transform: translate3d(206px, 48px, 0px) !important
  .react-datepicker__triangle
    position: absolute
    left: unset !important
    right: 50px !important

  .calendar-holder
    display: flex !important
    flex-direction: row !important
    width: 100% !important
    text-align: center
    justify-content: center
    align-items: center

  .no-data-wrapper
    display: flex
    width: 100%
    min-height: 800px !important
    justify-content: center
    align-items: center
    text-align: center
    p
      font-family: Roboto
      font-style: normal
      // font-weight: 500
      font-size: 16px
      line-height: 24px
      color: #BDBDBD

  .force-btn-group
    // background: #fff

    flex-wrap: nowrap
    display: flex
    flex-direction: column
    justify-content: center
    text-align: center
    /* padding: 16px;
    // position: absolute
    /* left: 45vw;
     *top: 40px;
    /* box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 24px 0px #0000001A
    width: 176px
    border-radius: 6px
    z-index: 5
    margin: 0
    padding: 0
    // height: 0
    &.centered-swither
      position: absolute
      left: calc( 50% - 120px )//170px // 43.1vw
      max-width: 264px
      transition: 0.5s
      .form_radio_group-item
        label
          height: 40px
          display: flex
          justify-content: center
          text-align: center
          align-items: center
        #radio-direct
          height: 40px
      // &.right-sidebar
      //   // margin-left: 0 
      //   left: 16px // 43.1vw
    &.connect-swither
      transition: 0.5s
      margin-left: 125px
      &.right-sidebar
        margin-left: 0
    button
      background: white
      border-radius: 6px

  .chevron
    background: white
    cursor: pointer
    height: 40px
    border-radius: 6px
    align-items: center
    display: flex
    padding-right: 8px

  .date-swither
    position: absolute
    // top: 63px
    right: 16px
  .date-item
    // display: flex
    // justify-content: center
    // align-items: center
    // text-align: center
    label
      min-width: 102px//92px
      border-radius: 0 0 6px 6px !important
    .export-container
      margin-left: 8px

  .date-btn-group
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    min-width: 88px
  .demo-group
    cursor: pointer
    box-shadow: 0px 0px 24px 0px #0000001A

  .demo-dash span,.date-swither span
    font-size: 14px
    display: inline-block
    // cursor: pointer
    padding: 8px // 8px 4px
    line-height: 24px
    border-radius: 6px
    // min-width: 88px
    background: #fff
  .user-data-table
    overflow-y: auto
    // max-height: calc( 100vh - 224px ) // need to turn on with area chart
    height: calc( 100vh - 224px - 85px) // need to turn on with area chart
    display: flex
    flex-direction: column
    &.long
      height: 100% !important//calc( 100vh - 100px ) // need to turn on with area chart
    .table-loader
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      top: 345px
      height: 352px
      .ui.text.loader
        position: unset !important
        left: unset
        right: unset
        margin-left: 55px
        width: 100%
        justify-content: center
        display: flex !important
  .user-data-table,.demo-dash-content
    // position: absolute
    top: 40px
    right: 0px
    width: 100%
    background: #fff
    border-radius: 0 0 6px 6px !important
    // box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1) !important
    // box-shadow: 0 16px 16px -16px rgba(0, 0, 0, 0.1),-16px 0 16px -16px rgba(0, 0, 0, 0.1),16px 0 16px -16px rgba(0, 0, 0, 0.1)

    .anm-container, .density-container, .inactive-container,.influencers-container,.individual-status-container
      position: relative !important
    .inactive-container
      pointer-events: all
      z-index: 15

    .area-chart-container
      width: calc( 100% - 32px )
      margin: 0 16px
  .metrics-container
    display: flex
    flex-direction: fow
    justify-content: flex-start//space-evenly
    align-items: center
    text-align: left
    margin: 0
    font-family: Roboto
    font-style: normal
    // line-height: 24px
    .metrics-item
      width: 96px
      // margin: 0 auto
      cursor: pointer
      padding: 8px
      margin: 11px auto 8px auto
      border: 1px solid #F7F7F7
      border-radius: 6px
      .subtext
        pointer-events: none
        font-size: 12px
        line-height: 16px
        font-weight: 500
        color: #333333 // #666666
      .bigtext
        pointer-events: none
        font-size: 24px
        line-height: 24px
        font-weight: 500//regular
        // margin-top: 4px !important
    .inactive-userlist, .anm-info, .density-info, .influencers-info, .missing-connections-count, .individual-contacts-info, .individual-messages, .individual-statistic-user-status, .individual-messages, .individual-contacts-info, .missing-connections-count
      // pointer-events: none
      position: absolute
      display: flex
      flex-direction: column
      justify-content: center
      align-items: start
      text-align: start
      z-index: 10
      width: 184px
      height: auto
      color: #000
      font-weight: normal
      background-color: #fff
      padding: 8px
      // margin: 6px
      // border: 1px solid #f2f2f2
      border-radius: 6px !important
      box-shadow: 0 16px 16px -16px rgba(0, 0, 0, 0.1),-16px 0 16px -16px rgba(0, 0, 0, 0.1),16px 0 16px -16px rgba(0, 0, 0, 0.1)
      span
        font-weight: 600
      p
        font-size: 12px !important
        line-height: 16px !important
        margin-bottom: 8px
    .inactive-userlist
      pointer-events: all
      // left: -82px
      right: 0
      top: 100px
      font-size: 12px !important
      line-height: 16px !important
      p
        font-size: 12px !important
        line-height: 16px !important
        margin-bottom: 8px
      .inactive-user-list-wrapper
        max-height: 300px
        width: 100%
        overflow-y: auto
        font-size: 12px !important
        line-height: 16px !important
        // margin-top: 8px
        .borderless-button
          margin-bottom: 8px
          font-size: 12px !important
          line-height: 16px !important
          overflow: hidden
          white-space: nowrap
          text-overflow: ellipsis
          width: 100%
          &:last-of-type
            margin-bottom: 0
    .anm-info
      left: 0//-82px
      top: 110px
    .density-info
      left: 40px
      top: 110px
    .influencers-info
      right: 60px
      top: 110px
    .individual-statistic-user-status
      left: 0//-82px
      top: 120px
    .individual-messages
      left: 82px
      top: 120px
    .individual-contacts-info
      right: 82px
      top: 120px
    .missing-connections-count
      right: 0
      top: 120px
  /////////////////////////////
  .text-pannel
    color: #000
    display: flex
    flex-direction: row
    margin: 8px 16px 0 16px// margin: 16px 16px 0 16px
    justify-content: space-between // center
    text-align: center
    align-items: center
    // position: relative
    font-size: 12px
    line-height: 24px
    &:nth-child(1)
      font-weight: 900
    .date-and-icon-group
      display: flex
      flex-direction: row
      justify-content: center
      text-align: center
      align-items: center
    .relation-type-label
      // position: absolute
      // right: 0
      color: #333333 !important //#666666 !important
    p
      margin: 0
      font-weight: 900 !important
      line-height: 24px !important
      &.date-label
        color: #333333 !important //#666666 !important
        font-weight: 500 !important
        margin-right: 8px
        // margin-left: 114px
    &.bottom
      margin: 0 !important
      justify-content: center
  .form-date-label
    cursor: unset
    pointer-events: none
    // font-weight: bold

  .chart-holder
    position: absolute
    top: 50px
    height: calc(100vh - 50px)
    width: 100%
    transition: 0.5s
    &.right-sidebar
      width: calc( 100% - 428px)
  #forceSvg
    // padding-top: 32px
    height: calc(100vh - 50px)//72px
    width: 100%
    overflow: hidden

.active-btn
  background: #000000 !important
  border-radius: 6px !important
  color: #fff !important
.filters-wrapper
  margin: 16px
  width: calc(100% - 32px)
  justify-content: space-between
  position: relative
  // margin-top: -1px !important
  transition: 0.5s
  top: -16px
  &.flex
    display: flex
  &.right-sidebar
    width: calc( 100% - 428px - 32px )
    // .connect-swither
    //   left: 43.1vw
    //   top: 63px
  .statistics-container
    display: flex
    flex-direction: column
    padding-top: 48px
    width: 100%

.period-container
  left: 65vw
  top: 40px
  display: flex

.date-btn-container
  position: absolute
  right: 0
  top: 0
  z-index: 5
  // float: right !important
  padding-right: 2px
  background: #fff
  flex-wrap: nowrap
  display: flex
  flex-direction: column
  justify-content: center
  text-align: center
  border-radius: 6px
  width: 424px !important
  box-shadow: 0px 0px 24px 0px #0000001A
  &.bottom-borderless
    // border-radius: 6px 6px 0 0 !important
    border-radius: 6px !important

.individual-statistic-container
  position: absolute
  left: 0
  top: calc( 40px + 16px )
  z-index: 5
  // float: right !important
  background: #fff
  flex-wrap: nowrap
  display: flex
  flex-direction: column
  justify-content: center
  text-align: center
  border-radius: 6px
  width: 424px !important
  box-shadow: 0px 0px 24px 0px #0000001A
  &.bottom-borderless
    // border-radius: 6px 6px 0 0 !important
    border-radius: 6px !important
  .user-data-table
    // max-height: calc( 100vh - 224px ) // need to turn on with area chart
    height: unset // need to turn on with area chart
    display: flex
    flex-direction: column
  .individual-statistic-wrapper
    margin-right: 2px
    overflow-y: auto
    max-height: calc(100vh - 354px)
    &.unscrolable
      height: unset !important
      overflow-y: hidden !important
      max-height: unset !important
  .individual-statistic-user
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    text-align: center
    // height: 40px
    padding: 8px
  .left-block
    display: flex
    justify-content: left
    flex-direction: row
    text-align: center
    align-items: center
    p
      font-family: Roboto
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 40px
  .user-avatar
    width: 40px
    height: 40px
    border-radius: 50%
    margin-right: 8px
.form_radio_group
  // display: inline-block
  display: flex
  justify-content: space-between
  overflow: hidden
  // box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1)  // box-shadow: 0px 0px 24px 0px #0000001A
  font-family: "Roboto"
  font-style: normal
  // font-weight: 500
  font-size: 14px
  line-height: 24px
  // background: #F2F2F24
  background: #fff
  border-radius: 6px
  /* padding: 0px 8px;
  /* identical to box height, or 171%
  text-align: center
  text-transform: capitalize
  // min-width: 88px
  &.bottom
    // padding-top: 13px 
    display: flex
    justify-content: space-between
    align-items: center
    // border-radius: 0 0 6px 6px

.square-corners
  width: 96%
  margin: auto
  border-radius: 0

.form_radio_group-item
  /* display: inline-block;
  float: left
  label
    color: #3D1D75
    // font-weight: normal

    &:hover
      background: #333333 !important
      color: #fff !important
      // font-weight: bold

.form_radio_group-item .relative
  position: relative !important

// .form_radio_group-item .disabled
//     label
//     color:#503483 !important
//     font-weight: normal

.form_radio_group
  input[type=radio]
    display: none

  label
    /* height: 40px;
     *display: flex;
     *justify-content:center;
     *text-align: center;
    font-size: 14px
    display: inline-block
    cursor: pointer
    padding: 8px 4px
    line-height: 24px
    // font-weight: normal
    /* border: 1px solid #999;
    border-right: none
    user-select: none
    background: #FFFFFF
    border-radius: 6px// 0 0 6px 6px //6px
    min-width: 88px
    // color: #3D1D75 !important
    // &:hover
    //   color: #000 !important

  // .form_radio_group-item
  //   &:first-child label
  //     border-radius: 6px 0 0 6px

  //   &:last-child label
  //     border-radius: 0 6px 6px 0
  //     border-right: 1px solid transparent

  input[type=radio]:checked + label
    // pointer-events: none
    background: #000
    color: #fff
    // font-weight: bold
    .calendar-container
      background-image: $calendarSelectedDark

  label:hover
    color: #666
    .calendar-container
      background-image: $calendarSelectedDark

input[type=radio]:checked + label
  background: #000
  color: #fff
  // font-weight: bold
  .calendar-container
    background-image: $calendarSelectedDark
label:hover
  color: #666
  .calendar-container
    background-image: $calendarSelectedDark

  input[type=radio]:disabled + label
    color: #A395BD
    background: #FFFFFF
    pointer-events: none
    // font-weight: normal
    .calendar-container
      background-image: $calendarDisabled

input[type=radio]:disabled + label
  color: #A395BD !important
  background: #FFFFFF !important
  pointer-events: none !important
  // font-weight: normal !important
  .calendar-container
    background-image: $calendarDisabled

  // DARK
.dark .ForcePage
  .force-wrapper
    background: #131313
  .search-pannel
    background-color: #222222 !important
  .search-container
    background-color: #222222 !important
  //   background-image: $searchDark //$searchSelected $searchSelected $searchDark $searchSelectedDark
  //   &:hover, &.selected
  //     background-image: $searchSelectedDark
  .dash-search
    i.icon
      color: #686868
    &:focus
      i.icon
        color: #f2f2f2 !important
  .dashbord-search-container
    background-color: #222222
    .user-holder
      color: #BDBDBD
      &:hover
        background-color: #333333
        p
          color: #fff !important
  .individual-statistic-container
    background: #222222
    color: #f2f2f2
    border-color: none

  .metrics-container
    .metrics-item
      border: 1px solid #333
    .subtext
      color: #686868 !important// #666666
    .inactive-userlist,.anm-info,.density-info,.influencers-info,.individual-statistic-user-status,.individual-messages,.individual-contacts-info,.missing-connections-count
      color: #f2f2f2
      background-color: #222
      // border-color: #333
      box-shadow: 0 16px 16px -16px rgba(128, 128, 128, 0.1),-16px 0 16px -16px rgba(128, 128, 128, 0.1),16px 0 16px -16px rgba(128, 128, 128, 0.1)

  .bigtext
    color: #f6f6f6
  .table-loader
    .ui.text.loader
      color: #686868
  .react-datepicker
    border: 1px solid #222222
  .react-datepicker__header,.react-datepicker,.react-datepicker__current-month
    background-color: #222222
    color: #bdbdbd
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name
    background-color: #222222
    color: #bdbdbd
    &:hover
      background-color: #ededed !important
      color: #222222 !important
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range
    background-color: #F2F2F2 !important
    color: #222222 !important
    &:hover
      background-color: #ededed !important
      color: #222222 !important

  .user-data-table,.demo-dash-content
    background: #222222
  .no-data-wrapper
    background: #131313
    p
      color: #686868
  .text-pannel
    color: #686868
    .relation-type-label
      color: #686868 !important //#666666 !important
    p
      &.date-label
        color: #686868 !important //#666666 !important
  .date-btn-container
    background: #222222
    &.bottom-borderless
      border-color: #222222
      
      // border-radius: 6px 6px 0 0 !important

  // .d3-chart-wrapper, .force-btn-group ,svg
    // background: #131313

  .date-swither span
    background: #222222
    color: #f2f2f2

  .form_radio_group-item, .form_radio_group
    background: #222222

  .form_radio_group-item
    label
      &:hover
        background: #333333 !important
        color: #BDBDBD !important

  .active-btn
    background: #BDBDBD !important
    color: #F2F2F2 !important

  label
    background: #222222
    color: #bdbdbd

  input[type=radio]:checked + label
    background: #5D5D5D
    color: #f2f2f2

  label:hover
    color: #f2f2f2

  input[type=radio]:disabled + label
    background: #222 !important
    color: #686868 !important
    .calendar-container
      background-image: $calendarDisabledDark

  .customTable
    .table
      background: #222222 !important
      color: #bdbdbd

  .demo-dash
    background-color: #222222 !important
    div,p
      background-color: #222222 !important
      color: #bdbdbd !important
      .description
        .list-item
          .list-unit
            color: #000 !important
            background: #fff !important

@media screen and (max-width: 1490px)
  .ForcePage
    .force-btn-group
      &.centered-swither
        &.right-sidebar
          // margin-left: 0 
          left: 0 // 16px// 43.1vw
    .demo-dash
      &.right-sidebar
        top: 47px
    .search-pannel
      &.right-sidebar
        top: 47px
@media screen and (max-width: 1152px)
  .ForcePage
    .filters-wrapper
      justify-content: space-between !important

@media screen and (max-width: 970px)
  .ForcePage
    // .dash-search
    //   border-radius: 6px 6px 0 0 
    .search-container
      top: 5px !important
    .search-pannel
      input
        height: 32px !important
    .dashbord-search-container
      position: absolute
      display: block
      width: 100%
      top: 30px
      left: unset
      border-radius: 0 0 6px 6px
      z-index: 22
      max-height: 50vh
      overflow-y: auto
      box-shadow: 0 24px 24px 0 rgb(0 0 0 / 10%)
      .user-holder &:first-of-type
        margin-top: 8px !important
    .demo-dash .demo-dash-container
      width: 100% !important
      box-shadow: 0px 0px 24px rgb(0 0 0 / 10%) !important
    .demo-dash
      .list-unit
        width: 16px !important
        height: 16px !important
        line-height: 16px !important
    .date-btn-container
      width: 320px !important
    .force-btn-group  //,.demo-header .demo-dash .description, .demo-dash a
      font-size: 11px !important

      flex-wrap: nowrap
      display: flex
      flex-direction: column
      justify-content: center
      text-align: center
      box-shadow: 0 0 24px 0 rgb(0 0 0 / 10%)
      width: 176px
      border-radius: 6px
      z-index: 5
      margin: 0
      padding: 0

    .force-btn-group.centered-swither
      left: calc( 50% - 87px )
      max-width: 116px
    // .description p //.demo-dash .description, .demo-dash a 
    //   line-height: 16px !important
      .list-item
        p
          line-height: 24px !important
    .connect-swither
      // top: 50px !important
      left: 0 !important
    .date-swither
      // top: 50px !important
      right: 0 !important
    .date-swither span
      display: none
      font-size: 12px !important
      padding: 8px 4px
      line-height: 24px
      border-radius: 6px
      min-width: 48px !important
    .form_radio_group
      label
        font-size: 11px !important
        padding: 8px 4px
        line-height: 24px
        border-radius: 6px
        min-width: 58px !important
    .form_radio_group-item
      input
        font-size: 11px !important
        min-width: 58px !important
    // #forceSvg
    //   padding-top: 32px !important

@media screen and  (max-width: 767px)
  .ForcePage
    // .chart-holder
    //   position: absolute
    .filters-wrapper
      top: -10px !important
    .search-pannel
      position: absolute
      left: 16px
      top: 16px
      // position: static
      z-index: 9 !important
      min-height: 32px !important
      height: 32px !important
      &.short
        width: 32px !important
        .search-input-holder
          .dash-search
            input
              height: 32px !important
          .search-container
            top: 4px
            right: 4px
    .user-data-table
      height: calc( 100vh - 324px )
    .filters-wrapper
      padding: 16px
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      margin: 0 6px !important
      // margin-top: -15px !important
      width: calc( 100% - 12px ) !important
      .statistic-container
        display: flex !important
        flex-direction: column !important
        position: static !important
        top: 50px !important
        width: 100% !important
      .form_radio_group label
        padding: 4px 4px !important
        height: 32px !important
      .force-btn-group.demo-dash
        position: static!important
        top: unset
        left: unset
        width: 100%
        display: flex
        margin-bottom: 16px 
        top: 0 !important
        left: 0 !important
        z-index: 6
        .demo-dash-content
          z-index: 20 !important
          padding: 0 10px
      .force-btn-group.centered-swither
        // position: static
        max-width: unset
        right: 0
        left: unset
        width: unset
        width: 100%
        position: relative
        height: 32px
        /* color: transparent !important; */
        /* background-color: transparent !important; */
        box-shadow: none
        .form_radio_group
          box-shadow: 0px 0px 24px 0px #0000001a
          position: absolute
          right: 0
          // width: 100% !important
          margin: 0 auto !important
          // justify-content: center !important
        // &.demo-visible
        //   right: 0 !important
        //   left: unset !important
        //   max-width: 256px
        //   // left: 6px
        //   width: unset
          .form_radio_group
            width: unset !important
            margin: unset !important
            justify-content: space-between !important
            
      .date-btn-container
        position: static !important
        top: 48px !important
        // z-index: 5 !important
        width: 100% !important
        left: 0 !important
        &.bottom-borderless //date-btn-container bottom-borderless
          // border-radius: 6px 6px 0 0 !important
          border-radius: 6px !important
          position: static !important
          margin-bottom: 16px !important
      .chevron
        height: 32px !important
    .individual-statistic-container
      position: static !important // position: absolute
      margin-bottom: 16px
      left: unset // 0
      top: unset // calc( 40px + 8px )
      z-index: 5
      width: 100% !important
      &.bottom-borderless
        // border-radius: 6px 6px 0 0 !important
        border-radius: 6px !important
        position: static !important
        margin-bottom: 16px !important
      .user-data-table
        height: calc( 100vh - 350px ) // need to turn on with area chart
      .individual-statistic-wrapper
        height: calc( 98vh - 200px )
        max-height: 100%

      .individual-statistic-user
        padding: 8px
      .left-block
        display: flex
        justify-content: left
        flex-direction: row
        text-align: center
        align-items: center
        p
          font-family: Roboto
          font-style: normal
          font-weight: 500
          font-size: 14px
          line-height: 40px
      .user-avatar
        width: 40px
        height: 40px
        border-radius: 50%
        margin-right: 8px

    .customTable .ui.table td:nth-of-type(5)    
      padding-right: 25px !important
    .customTable .table-header div
        margin: -0.5px
  .filters-wrapper .statistics-container
    padding-top: 16px
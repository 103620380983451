.d3-area-chart-wrapper 
    // width:408px
    height:278px
   
    .axis
        path, line
            fill: none
            stroke: black

    .x2 .tick
        &:first-of-type
            text-anchor: start

        &:last-of-type
            text-anchor: end

    .selection
        stroke: none

    .brushHolder .selection
        fill: #3D1D75
        fill-opacity: 0.15

    .domain
        display: none

    .tick text
        font-size: 10px
        fill: #333333
        color: #333333
    .tick line
        stroke: #666
        opacity: 0.4 !important

    .frame
        fill: none !important
        stroke: lightgrey
        stroke-opacity: 0.7
        shape-rendering: crispEdges
        stroke-dasharray: 5 5

    .tick line
        opacity: 0.2
        stroke-dasharray: 5 5
        stroke-linecap: round


    .line
        fill: none
        stroke: #27C245
        stroke-width: 2px
        stroke-linecap: round

    .zoom
        cursor: move
        fill: none
        pointer-events: all

    .area
        // fill: url(#area-gradient)
        fill: transparent
        stroke-width: 0px

    .area2
        // fill: url(#area2-gradient)
        fill: transparent
        stroke-width: 0px

    .grid
        line
            stroke: lightgrey
            stroke-opacity: 0.7
            shape-rendering: crispEdges
            stroke-dasharray: 5 5
            // stroke-color: #333

        path
            stroke-width: 0
            stroke-dasharray: 5 5

    .main-line, .context-line
        stroke: #333
        stroke-width: 1px
        stroke-linecap: round

    .brush-selector
        stroke: #3D1D75
        pointer-events: none
        stroke-width: 1.5px

    .brush-selector-line
        stroke: #3D1D75
        pointer-events: none
        stroke-width: 1.5px
        stroke-linecap: round

    .brush-selector
        fill: #fff
    .tooltipArea > rect  
        fill: #fff
        stroke-width: 1px
        stroke: #f2f2f2
    // .tooltipArea
    //     text:first-of-class    
    //         stroke: #27C245

    .tooltipCircle
        fill: #fff

    &.dark
        
        text
            // fill: #f2f2f2
            // fill: #27C245
            
            fill: #686868
            color: #686868
        .brush-selector
            stroke: #686868
        .brush-selector-line
            stroke: #686868
        
        .brushHolder .selection
            fill: #BDBDBD

        .tick line,.frame, .main-line, .context-line //, .selection
            stroke: #686868 !important
        .frame
            stroke-opacity: 0.4 !important
        .tooltipArea > rect  
            fill: #333
            stroke-width: 1px
            stroke: #333
        .tooltipArea
            text
                &:nth-child(2)
                    fill: #27C245
                &:nth-child(3)
                    fill: #27C245
        
        .tooltipCircle
            fill: #333  






// @media screen and  (max-height: 800px)
// .d3-area-chart-wrapper 
//     // width:408px
//     height:302px
   
.SettingsPage
  display: flex
  justify-content: center
  align-items: center
  background-color: #F2F2F2
  // .content-box
  //   position: relative
  //   top: 64px
  .settings-activation-link
    width: 100%
    position: relative
    .copy-input
      width: 100%
      input
        height: 40px
    .copy-button
      position: absolute
      top: 33px
      right: -3px
      height: 40px
      background: #503483 !important
      color: #fff !important
      border-radius: 6px
      &:hover
        background: #000 !important
      & .disabled
        background: #A395BD !important
  .invitationForm
    width: 100%
    h5
      font-weight: 14px
  .settings-wrapper
    margin-top: 0 //24px
    margin-bottom: 16px
    padding: 24px
    width: 576px
    height: auto
    background: #FFFFFF
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1)
    border-radius: 6px
    display: flex
    position: relative
    
    /* justify-content: center;
    align-items: baseline
    flex-direction: column
    .connection-title
      margin-bottom: 24px
      font-weight: 700 !important
    hr
      margin: 24px 0px 24px -4px
      border: none
      background-color: #D8D8D8
      height: 1px
      width: calc(100%)

    h5
      font-family: 'Roboto'
      font-style: normal
      // font-weight: bold
      font-size: 16px
      line-height: 24px
      color: #000000
      margin-bottom: 8px// 24px

  .settings-item
    display: flex
    justify-content: space-between
    align-items: center
    flex-direction: row
    width: 100%

  .item-text
    float: left
    width: 68%

    span
      font-family: 'Roboto'
      font-style: normal
      // font-weight: bold
      font-size: 14px
      line-height: 24px
      color: #000000

    p
      font-family: 'Roboto'
      font-style: normal
      // font-weight: normal
      font-size: 12px
      line-height: 16px
      color: #666666

  .settings-wrapper .settings-item button
    float: right
    // min-width: 156px
    width: 40%
    height: 40px
    border-radius: 6px
    background: #503483
    font-family: 'Roboto'
    font-style: normal
    // font-weight: bold
    font-size: 14px !important
    line-height: 16px
    line-height: 0
    &:hover
      background: #000
    & .disabled
      background: #A395BD



    /* identical to box height, or 114%

    text-align: center
    color: #FFFFFF

.dark
  .SettingsPage
    background-color: #131313
    .settings-wrapper
      background: #222222 !important
      color: #bdbdbd !important
    span,h5
      color: #bdbdbd !important
    .button
      background: #BDBDBD !important
      color: #333333 !important
      &:hover
          background: #f2f2f2 !important
      & .disabled
          background: #686868 !important
    hr
      background-color: #4F4F4F

@media screen and (max-width: 767px)
  .SettingsPage
    .settings-wrapper
      width: auto !important
      padding: 0 10px 16px 10px !important
      margin-bottom: 16px
    .error
      padding: 0 !important

// @media screen and (max-height: 1000px)
//   .App
//     .page-content
//       background-color: #F2F2F2
//       height: calc(100% - 72px) // 48height + 24px margin bottom = 72px
//       &.unautentificated
//         height: 100%
  
  // .SendInvitesToSlackUsers
//   background-color: #F2F2F2
//   display: flex
//   justify-content: center
//   align-items: center
//   // .content
//   //   display: flex
//   //   flex-direction: column
//   //   align-items: center
//   //   justify-content: center
//   .invitationForm,.content
//     width: 100%
//   .error-message-container
//     display: flex
//     flex-direction: column
//     justify-content: center
//     align-items: center
//     // width: 352px
//   .copy-button-container
//     .copy-input
//       width: 100%
//   p
//     line-height: 24px
//   h2
//     margin: 40px auto 32px
//   h3
//     font-size: 16px
//     text-align: start
//     // font-weight: bold !important
//   // .content-box
//   //   position: relative
//   //   top: 64px
//   .slack-connection-wrapper
//     margin-top: 0 //24px
//     padding: 16px 24px 64px 24px !important
//     width: 576px
//     // height: 232px
//     background: #FFFFFF
//     box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1)
//     border-radius: 6px
//     display: flex
//     position: relative
//     /* justify-content: center;
//     align-items: flex-start //center
//     flex-direction: column

//   .user-avatar
//     width: 24px
//     height: 24px
//     border-radius: 50%
//   .login-opts-divider
//     display: flex
//     flex-direction: row
//     justify-content: center
//     align-items: center
//     margin-top: 22px
//     margin-bottom: 24px
//     color: #D8D8D8

//     hr
//       width: 45%
//       height: 1px
//       border: 0
//       border-top: 1px solid #D8D8D8
//   .slack-btn
//     background: #fff !important
//     border: #503483 1px solid !important
//     span
//       color: #503483
//     &:hover
//       background: #000 !important
//       span
//         color: #fff
//   input
//     border: #503483 1px solid !important
//   .slack-btn, input
//     width: 352px !important
//     height: 40px
//     display: flex !important
//     justify-content: center !important
//     align-items: center !important
//     text-align: center !important
//   .copy-button
//     position: absolute
//     right: 0
//     top: 0
//     height: 40px
//     background: #503483 !important
//     color: #fff !important
//     border-radius: 6px
//     &:hover
//       background: #000 !important
//     & .disabled
//       background: #A395BD !important
//   .accentuated
//     font-size: 14px !important
//     font-weight: 500 !important
//   textarea
//     min-height: 56px !important
//     height: 56px !important

// .dark
//   .SendInvitesToSlackUsers
//     background-color: #131313
//     .big-button
//       color: #333333 !important
//     .slack-connection-wrapper
//       background: #222222 !important
//       color: #bdbdbd !important
//     span,h5
//       color: #bdbdbd !important
//     .copy-button
//       background: #BDBDBD !important
//       color: #333333 !important
//       &:hover
//           background: #f2f2f2 !important
//       & .disabled
//           background: #686868 !important
//     hr
//       background-color: #4F4F4F
//     .slack-btn
//       border: 2px solid #BDBDBD !important
//       background: #222222 !important
//       &:hover
//         border-color: #f2f2f2 !important
//         & span
//           color: #333333 !important
//       span
//         color: #BDBDBD !important
//       //////////////
// // @media screen and (max-width: 900px)
// //   .SendInvitesToSlackUsers
// //     .item-text
// //       float: left
// //       width: unset
// //     .ConnectSlack-wrapper .ConnectSlack-item button
// //       width: unset



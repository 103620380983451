// @import '../index.sass'  
$closeDark: url('../img/close-dark.svg')
$closeSelectedDark: url('../img/close-selected-dark.svg')
$closeDisabledDark: url('../img/close-disabled-dark.svg')

// .ui.mini.modal
//     width: 400px !important

.ui.modal
    .scrolling.content
        width: 97% !important
    //     &.delete-acc
    //         display: flex
    //         flex-direction: row
    //         justify-content: space-between
    //         align-items: center

.customModal
    width: 400px
    border: none !important
    border-radius: 8px !important
    &.cancel
        max-width: 576px //!important
        .actions
            display: flex
            float: right
            &.delete-acc
                width: 100%
                display: flex
                flex-direction: row
                justify-content: space-between
                align-items: center
            .cancel-button
                background: #fff !important
                color: #3D1D75 !important
                &:hover
                    color: #000 !important
        .ui.checkbox .box:before, .ui.checkbox label:before
            background: unset !important
            // border-radius: 2px
            border: 1px solid #3D1D75
        .ui.checkbox .box:hover, .ui.checkbox label:hover, .ui .checked .checkbox input:focus
            &:before
                border-radius: 2px
                border: 1px solid #000
        .ui.checkbox input:checked:focus~label:before
            border-color: #000

    
    .ui.selection.dropdown:focus
        border-color: unset !important
    .ui.selection.active.dropdown
        .menu
            border-color: #503483 !important
        &:hover .menu
            border-color: #000000 !important
    .ui.active.selection.dropdown
        border-radius: 6px !important
        border-color: unset !important
    .ui.dropdown .menu .selected.item
        background: #fff
    .ui.selection.active.dropdown .menu
        border-color: #3D1D75 !important
        margin: 0 !important
        border-width: 1px !important
        border-top: none !important
        left: -1px !important
        top: 37px
        width: calc(100% - 1px) !important
        border: none !important
        border-radius: 6px
        .item
            border: none !important
            color: #3D1D75
            font-weight: 400
            &:hover
                background-color: #F7F7F7
                color: #333333
                border-radius: 6px !important
            &.disabled
                color: #BDBDBD
            &.active
                background-color: #fff// !important // HERE IS
                span
                    color: #000 !important
                &:hover
                    background-color: #F7F7F7 !important
                    color: #333333
                    border-radius: 6px !important
    .ui.selection.dropdown .menu>.item
        border: none !important


    .checkbox-wrapper
        display: flex !important
        flex-direction: column
        margin: 24px 0 0 0
        line-height: 24px !important
        p
            font-size: 14px
            margin: 0
        .checkbox-custom
            margin: 8px 0 !important
            float: left
            line-height: 24px
            font-size: 14px !important
            font-weight: normal
            label
                margin: 0 !important
                font-style: normal !important
                font-weight: normal !important
                font-size: 14px !important
                line-height: 24px
                &::before
                    top: 3px
            &:last-of-type    
                margin: 8px 0 0 0 !important

    .ui.checkbox .box:before, .ui.checkbox label:before
        background: unset !important
        // border-radius: 2px
        border: 1px solid #3D1D75
    .ui.checkbox .box:hover, .ui.checkbox label:hover, .ui .checked .checkbox input:focus
        &:before
            // border-radius: 2px
            border: 1px solid #000
    .ui.checkbox input:checked:focus~label:before
        border-color: #000

    .ui.fitted.toggle.checkbox
        width: 38px
        min-height: 16px !important
    .ui.toggle.checkbox
        min-height: unset !important
    .ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before, .ui.toggle.checkbox input:checked~label:before
        background-color: #503483 !important
    .ui.toggle.checkbox label
        &:hover
            &::before
                background: #3D1D75
    &::before
        width: 38px
        height: 20px
        background: #BDBDBD
        &::after
            width: 16px
            height: 16px
            top: 2px
            left: 2px
            box-shadow: none

    .ui.toggle.checkbox input:checked~label:after
        left: 20px
        box-shadow: none


    .close-container
        position: absolute
        top: 24px !important
        right: 24px !important
    .header
        border-bottom: none !important
        font-size: 16px !important
        border-radius: 6px 6px 0 0 !important
        padding: 24px 24px 20px 24px !important
    .content
        font-size: 14px !important
        padding: 0 24px !important
    .actions
        background: #fff !important
        border-top: none !important
        border-radius: 0 0 6px 6px !important
        padding: 24px !important
        &.center
            display: flex
            justify-content: center
    .cancel-container
        position: absolute
        top: 24px !important
        right: 24px !important
        color: #3D1D75 !important
        &:hover
            color: #333333 !important
    .button
        background: #3D1D75 !important
        color: #fff !important
        font-size: 14px !important
        height: 40px im !important
        padding: 0.93em 1.5em 0.93em !important
        &.wide
            width: 208px
            margin-left: 0 !important
            margin: 0 auto !important
        &.widest
            width: calc( 100% - 32px )
        &:hover
            background: #333333 !important
        &.error
            background: #E01E5A !important
            &:hover
                background: #C71A51 !important
            &.disabled
                background: #EB8AA8 !important
        &.borderless-button
            font-style: normal !important
            font-weight: normal !important
            font-size: 14px !important
            line-height: 24px
            font-family: Roboto
            font-style: normal
            font-weight: normal
            font-size: 12px
            line-height: 24px
            color: #3D1D75 !important
            background: unset !important
            border: none !important
            &:hover
                color: #000
    textarea
        border: none !important
        background: #F5F5F5 !important
        border-radius: 6px
        outline: none !important
        resize: none !important
        padding: 8px
        border: 1px solid #503483 !important
        color: #503483 !important
        background: #fff !important
        border-radius: 6px !important
        width: 100%
        height: 72px
        &:focus-visible, &:focus
          border: 1px solid #000000 !important
          color: #000000 !important
    .borderless-button
        font-family: Roboto
        font-style: normal
        font-weight: normal
        font-size: 12px
        line-height: 24px
        color: #BDBDBD
        &:hover
            color: #F2F2F2
    &.dark
        background: #222222 !important
        &.cancel
            // width: 576px// !important
            .actions
                display: flex
                float: right
                .cancel-button
                    background: #222222 !important
                    color: #BDBDBD !important
                    &:hover
                        color: #f2f2f2 !important

        .ui.selection.active.dropdown
            .menu
                border-color: #F2F2F2 !important
            &:hover.menu
                border-color: #ffffff !important
        .ui.selection.visible.dropdown>.text:not(.default)
            color: #f2f2f2 !important
        .ui.dropdown .menu .selected.item
            background: #222
        .ui.selection.active.dropdown .menu
            .active .selected
                background: #333333 !important
            .item
                border: none !important
                color: #3D1D75
                font-weight: medium
                .text
                    color: #BDBDBD !important
                &:hover
                    background-color: #333333
                    color: #f2f2f2
                    border-radius: 6px !important
                    .text
                        color: #f2f2f2 !important
                &.disabled
                    color: #BDBDBD
                &.active
                    background-color: #222 !important
                    color: #fff !important
                    // background-color: #fff !important
                    // font-weight: normal !important
                    span
                        color: #fff !important
                    &:hover
                        background-color: #333333 !important
                        color: #0f0505
                        border-radius: 6px !important





        .ui.checkbox .box:before, .ui.checkbox label:before
            background: unset !important
            border: 1px solid #BDBDBD
            color: #F2F2F2
        .ui.checkbox .box:hover, .ui.checkbox label:hover, .ui .checked .checkbox input:focus
            &:before
                border: 1px solid #f2f2f2
        .ui.checkbox input:checked:focus~label:before
            border-color: #f2f2f2
        .ui.checkbox
            label
                color:#F2F2F2
        .ui.checkbox input:checked~label:after
            color: #fff

        textarea
            background: #222222 !important
            color: #bdbdbd !important
            border: 1px solid #BDBDBD !important
            &::placeholder
                color: #BDBDBD !important
        textarea::placeholder
            color: #bdbdbd !important

        .close-container
            background-image: $closeDark !important
            &:hover, &.selected
                background-image: $closeSelectedDark !important
            &.disabled
                background-image: $closeDisabledDark !important
                pointer-events: none
        .ui.modal
            background: #222222 !important
        .actions
            background: #222222 !important
        .close
            color: #BDBDBD !important
        div
            background: #222222 !important
            color: #bdbdbd !important
        .button
            background: #BDBDBD !important
            color: #333333 !important
            &:hover
                background: #f2f2f2 !important
            & .disabled
                background: #686868 !important
            &.error
                background: #E01E5A !important
                &:hover
                    background: #C71A51 !important
                &.disabled
                    background: #7A1836 !important
            &.borderless-button
                background: none !important
                color: #BDBDBD !important
                &:hover
                    color: #F2F2F2 !important


@media screen and (max-width: 767px)
    .customModal
        width: 100% !important
        border: none !important
        border-radius: 8px !important
        &.cancel
            width: 100% !important
        .actions
            padding: 1rem 1rem !important

    .ui.modal>.actions
        padding: 1rem 1rem !important
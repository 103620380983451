.SettingsPage
  display: flex
  justify-content: center
  align-items: center
  background-color: #F2F2F2
  // .content-box
  //   position: relative
  //   top: 64px
  .settings-activation-link
    width: 100%
    position: relative
    .copy-input
      width: 100%
      input
        height: 40px
    .copy-button
      position: absolute
      top: 33px
      right: -3px
      height: 40px
      background: #503483 !important
      color: #fff !important
      border-radius: 6px
      &:hover
        background: #000 !important
      & .disabled
        background: #A395BD !important
  .invitationForm
    width: 100%
    h5
      font-weight: 14px
  .settings-wrapper
    margin-top: 0 //24px
    margin-bottom: 16px
    padding: 24px
    width: 576px
    height: auto
    background: #FFFFFF
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1)
    border-radius: 6px
    display: flex
    position: relative
    .settings-title
      margin-bottom: 24px
      font-weight: 700 !important
    /* justify-content: center;
    align-items: baseline
    flex-direction: column

    hr
      margin: 24px 0px 24px -4px
      border: none
      background-color: #D8D8D8
      height: 1px
      width: calc(100%)

    h5
      font-family: 'Roboto'
      font-style: normal
      font-weight: 500 
      font-size: 16px
      line-height: 24px
      color: #000000
      margin-bottom: 24px// 24px
    


  .settings-item
    display: flex
    justify-content: space-between
    align-items: center
    flex-direction: row
    width: 100%

  .item-text
    float: left
    width: 68%

    span
      font-family: 'Roboto'
      font-style: normal
      // font-weight: bold
      font-size: 14px
      line-height: 24px
      color: #000000

    p
      font-family: 'Roboto'
      font-style: normal
      // font-weight: normal
      font-size: 12px
      line-height: 16px
      color: #666666

  .settings-wrapper .settings-item button
    float: right
    // min-width: 156px
    width: 40%
    height: 40px
    border-radius: 6px
    background: #503483
    font-family: 'Roboto'
    font-style: normal
    // font-weight: bold
    font-size: 14px !important
    line-height: 16px
    line-height: 0
    &:hover
      background: #000
    & .disabled
      background: #A395BD



    /* identical to box height, or 114%

    text-align: center
    color: #FFFFFF

.dark
  .SettingsPage
    background-color: #131313
    .settings-wrapper
      background: #222222 !important
      color: #bdbdbd !important
    span,h5
      color: #bdbdbd !important
    .button
      background: #BDBDBD !important
      color: #333333 !important
      &:hover
          background: #f2f2f2 !important
      & .disabled
          background: #686868 !important
    hr
      background-color: #4F4F4F

@media screen and (max-width: 767px)
  .SettingsPage
    .settings-wrapper
      width: auto !important
      padding: 0 10px 16px 10px !important
      margin-bottom: 16px
    .error
      padding: 0 !important

// @media screen and (max-height: 1000px)
//   .App
//     .page-content
//       background-color: #F2F2F2
//       height: calc(100% - 72px) // 48height + 24px margin bottom = 72px
//       &.unautentificated
//         height: 100%
:root
  touch-action: pan-x pan-y
  height: 100% 
.Login
  background-color: #F2F2F2
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100vh
  flex-direction: column
  // touch-action: manipulation
  // &:root
  //   touch-action: pan-x pan-y
  //   height: 100% 
  // & *
  //   touch-action: manipulation
  //   &:root
  //     touch-action: pan-x pan-y
  //     height: 100% 
  
  // .logo-big-container
  //   background-image: url('../../img/logo.svg')
  //   // background: url('../../img/logo.svg')  no-repeatcenter center fixed
  //   background-size: cover
  //   -webkit-background-size: cover
  //   -moz-background-size: cover
  //   -o-background-size: cover
  //   // margin: 0
  //   // padding: 0
  //   // overflow: hidden
  //   width: 196px
  //   height: 48px
  // .additional-login-options
  //   display: flex
  //   flex-direction: row
  //   justify-content: space-between
  //   line-height: 24px
  //   align-items: center !important
  //   margin: 0 0 24px 0 !important
  //   font-family: Roboto
  //   font-style: normal
  //   font-weight: normal
  //   .checkbox-custom
  //     margin: 0 !important
  //     float: left
  //     line-height: 24px
  //     font-size: 14px !important
  //     font-weight: normal
  //     label
  //       margin: 0 !important
  //       font-style: normal
  //       font-weight: normal
  //       font-size: 14px
  //       line-height: 24px
  //       &::before
  //         top: 3px
  .pass-recover
    margin: 0 !important
    float: right
    font-size: 12px !important
    line-height: 16px
    font-weight: normal
    border: none !important
  .main-content
    width: 640px
    max-width: 640px
    min-width: 360px
    height: 680px
    background: #FFFFFF
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1)
    border-radius: 6px
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap
    flex-direction: column
    position: relative

  .signup-link-container
    position: absolute
    top: 24px
    right: 24px
    font-size: 12px
    line-height: 16px
    // margin-right: 8px
    span
      margin-right: 8px !important
    span,.login-link
      font-size: 12px
      line-height: 16px

  .sign-in-container
    margin-bottom: 64px

  .main-registration-form
    min-width: 352px

  .google-btn
    background: #503483 !important

  .slack-btn
    background: #fff !important
    &:hover
      border: #000 2px solid !important
      & span
        color: #fff !important
    &.disabled
      background: #fff !important

  .big-button
    width: 352px
    background: #503483 !important
    border-radius: 6px
    display: flex !important
    justify-content: center
    align-items: center
    text-align: center
    &:hover
      background: #000 !important
    & .disabled
      background: #A395BD !important

    img
      margin-right: 10px

    span
      color: #fff

  .slack-btn
    background: #fff !important
    border: #503483 2px solid !important

    span
      color: #503483

  .login-opts-divider
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center //flex-end
    margin-top: 22px
    margin-bottom: 24px
    color: #D8D8D8

    hr
      width: 45%
      height: 1px
      border: 0
      border-top: 1px solid #D8D8D8

  .pwrd-icon, .pwrd-icon-hidden
    position: absolute
    top: 7px
    right: 7px

// DARK
.dark

  .Login
    background-color: #131313
    .mail-to-container
      p
        color: #686868
  .big-button
    // width: 352px
    // background: #505050
    background: #BDBDBD !important
    &:hover
      background: #f2f2f2 !important
    & .disabled
      background: #686868 !important

  .google-btn
    background: #BDBDBD !important

  .slack-btn
    background: #222222 !important
    &:hover
      border-color: #f2f2f2 !important
      & span
        color: #333333 !important
  // .logo-big-container
  //   background-image: url('../../img/logo-dark.svg')

  .signup-link-container span
    color: #F2F2F2 !important

  .main-content
    background: #222222
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1)
    span
      color: #333333

  .slack-btn
    // background: #fff !important
    border: 2px solid #BDBDBD !important
    span
      color: #BDBDBD !important

  .login-opts-divider
    span
      color: #4f4f4f
    hr
      width: 45%
      height: 1px
      border: 0
      border-top: 1px solid #4f4f4f

@media screen and  (max-width: 767px)
  .Login
    .main-content
      width: 100%
      max-width: 640px
      min-width: 360px
    .service-item
      margin: 4px 0
      font-size: 10px
    .additional-container
      p,a
        font-size: 12px !important

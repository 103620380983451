$plus: url('../img/plus.svg')
$plusSelected: url('../img/plus-selected.svg')
$plusDisabled: url('../img/plus-disabled.svg')
$minus: url('../img/minus.svg')
$minusSelected: url('../img/minus-selected.svg')
$minusDisabled: url('../img/minus-disabled.svg')
$plusDark: url('../img/plus-dark.svg')
$plusSelectedDark: url('../img/plus-selected-dark.svg')
$plusDisabledDark: url('../img/plus-disabled-dark.svg')
$minusDark: url('../img/minus-dark.svg')
$minusSelectedDark: url('../img/minus-selected-dark.svg')
$minusDisabledDark: url('../img/minus-disabled-dark.svg')
// #zoomPannel
//   &::after
//     content: $plus $plusSelected $plusDisabled $minus $minusSelected $minusDisabled $plusDark $plusSelectedDark $plusDisabledDark $minusDark $minusSelectedDark $minusDisabledDark
//     position: absolute
//     width: 0
//     height: 0
//     overflow: hidden
//     z-index: -1
.d3-chart-wrapper 
  background-color: #f7f7f7
#forceSvg
  cursor: grab
  .messageRect-shadow .lablelRect-shadow .dialogue-triangle-shadow
    fill: #DEDEDE
    stroke: none

  .avatar-clip
    background: transparent

  .link
    stroke: #E4E1EA //#9A8AB6
    // stroke-opacity: 0.3
    pointer-events: none
    fill: none

  text
    font-family: 'Roboto' !important
    stroke-width: 0.1 !important
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    -o-user-select: none
    user-select: none

  .avatar, .messageRect, .dialogue-triangle, .messageCount, .circle, .lablel, .lablelRect,.circle-shadow, #avatar-clip, .avatar-archive, .avatar-letter, .avatar-clock, #blur , .lablelRect, .messageRect-shadow, .dialogue-triangle-shadow, .messageRect, .dialogue-triangle, .messageCount
    cursor: pointer !important
    pointer-events: none !important

  .lablelRect
    fill: #f7f7f7// #F2F2F2
    stroke: none
    stroke-width: 1px

  .messageRect, .dialogue-triangle
    fill: #3D1D75
    stroke: none

  .messageCount
    text-anchor: start
    font-size: 10px !important
    pointer-events: none
    fill: #F2F2F2
    stroke: none

  .circle
    fill: #F2F2F2
    stroke: #3D1D75
    stroke-width: 2px

  .lablel
    text-anchor: middle
    pointer-events: none !important
    font-size: 12px !important
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    -o-user-select: none
    user-select: none

  #form label
    cursor: pointer

  label
    float: left
    padding: 0px 1em 0px 8px

  input.radio
    float: left

#zoomPannel
  position: absolute
  // min-width: 64px
  // height: 40px
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  bottom: 16px
  right: 16px
  color: #3D1D75
  background: #FFFFFF
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1)
  border-radius: 6px
  padding: 12px
  // transition: 1px
  // &.right-sidebar
  //   right: calc( 16px + 428px)
  &::after
    content: $plus $plusSelected $plusDisabled $minus $minusSelected $minusDisabled$plusDark $plusSelectedDark $plusDisabledDark $minusDark $minusSelectedDark$minusDisabledDark
    position: absolute
    width: 0
    height: 0
    overflow: hidden
    z-index: -1
  #zoomDimensionDiv
    font-size: 14px
    border: none
    height: 24px
    width: 36px
    margin: 0
    padding: 0
    // margin-right: 12px
    line-height: 1
    cursor: pointer
    display: flex
    text-align: center
    align-items: center
    justify-content: center
    &:hover, &.selected
      color: #000

  button
    font-size: 14px
    border: none
    height: 24px
    width: 24px
    margin: 0
    padding: 0
    // margin-right: 12px
    cursor: pointer
    display: flex
    line-height: 1
    align-items: center
    &:hover, &.selected
      color: #000
      // font-weight: bold

  .minus-container,.plus-container
    width: 24px
    height: 24px
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    margin-right: 12px
    cursor: pointer
    &.disabled
      pointer-events: none
      cursor: unset

  .plus-container
    background-image: $plus
    &:hover, &.selected
      background-image: $plusSelected
    &.disabled
      background-image: $plusDisabled
  .minus-container
    background-image: $minus
    &:hover, &.selected
      background-image: $minusSelected
    &.disabled
      background-image: $minusDisabled

  .plus-minus-container
    flex-direction: row
    display: none

  // .preloadContainer
  //   position: absolute
  //   overflow: hidden
  //   z-index: -1
  //   top: 0
  //   right: 0
  //   div
  //     width: 0
  //     height: 0
  //   #preloadPlus
  //     background-image: $plus$helpDisabledDark
  //   #preloadPlusSelected
  //     background-image: $plusSelected$helpDisabledDark
  //   #preloadPlusDisabled
  //     background-image: $plusDisabled$helpDisabledDark
  //   #preloadMinus
  //     background-image: $minus$helpDisabledDark
  //   #preloadMinusSelected
  //     background-image: $minusSelected$helpDisabledDark
  //   #preloadMinusDisabled
  //     background-image: $minusDisabled$helpDisabledDark

.dark
  .d3-chart-wrapper 
    background-color: #131313
  #forceSvg
    .messageRect-shadow .lablelRect-shadow .dialogue-triangle-shadow
      fill: #dadada

    .circle
      fill: #222222
      stroke: #bdbdbd

    .link
      stroke: #404040 !important //was #828282
      fill: #404040 !important //was #828282
    .lablelRect
      fill: #131313

    .messageRect, .dialogue-triangle
      fill: #bdbdbd

    .messageCount
      fill: #222222

    .lablel
      fill: #bdbdbd

  #zoomPannel
    color: #BDBDBD
    background: #222222

    #zoomDimensionDiv
      color: #bdbdbd
      background: #222222
      &:hover
        color: #f2f2f2
    .plus-container
      background-image: $plusDark !important
      &:hover, &.selected
        background-image: $plusSelectedDark !important
      &.disabled
        background-image: $plusDisabledDark !important
    .minus-container
      background-image: $minusDark !important
      &:hover, &.selected
        background-image: $minusSelectedDark !important
      &.disabled
        background-image: $minusDisabledDark !important
    // #preloadPlus
      // background-image: $plusDark
    // #preloadPlusSelected
      // background-image: $plusSelectedDark
    // #preloadPlusDisabled
      // background-image: $plusDisabledDark
    // #preloadMinus
      // background-image: $minusDark
    // #preloadMinusSelected
      // background-image: $minusSelectedDark
    // #preloadMinusDisabled
      // background-image: $minusDisabledDark

div.tooltip
  position: absolute
  text-align: left
  width: 0// 184px
  height: 0//84px
  top: 0
  left: 0



        
@media screen and  (max-width: 800px)
  #zoomPannel
    bottom: calc( 16px + 108px )
$roboto: url("./fonts/Roboto/Roboto-Regular.ttf")

$logo: url('./img/logo.svg')
$logoMini: url('./img/logo-mini.svg')
$blocks: url('./img/blocks.svg')
$blocksSelected: url('./img/blocks-selected.svg')
$gear: url('./img/gear.svg')
$gearSelected: url('./img/gear-selected.svg')
$gearDisabled: url('./img/gear-disabled.svg')
$user: url('./img/user.svg')
$userSelected: url('./img/user-selected.svg')
$userDisabled: url('./img/user-disabled.svg')
$logout: url('./img/logout.svg')
$logoutSelected: url('./img/logout-selected.svg')
// $logoutDisabled: url('./img/logout-disabled.svg')
$chevronBottom: url('./img/chevron-bottom.svg')
$chevronBottomSelected: url('./img/chevron-bottom-selected.svg')
$eye: url('./img/eye.svg')
$eyeSelected: url('./img/eye-selected.svg')
$eyeHidden: url('./img/eye-hidden.svg')
$eyeHiddenSelected: url('./img/eye-hidden-selected.svg')
$help: url('./img/help.svg')
$helpSelected: url('./img/help-selected.svg')
$logoDark: url('./img/logo-dark.svg')
$logoMiniDark: url('./img/logo-mini-dark.svg')
$blocksDark: url('./img/blocks-dark.svg')
$blocksSelectedDark: url('./img/blocks-selected-dark.svg')
$gearDark: url('./img/gear-dark.svg')
$gearSelectedDark: url('./img/gear-selected-dark.svg')
$gearDisabledDark: url('./img/gear-disabled-dark.svg')
$userDark: url('./img/user-dark.svg')
$userSelectedDark: url('./img/user-selected-dark.svg')
$userDisabledDark: url('./img/user-disabled-dark.svg')
$logoutDark: url('./img/logout-dark.svg')
$logoutSelectedDark: url('./img/logout-selected-dark.svg')
// $logoutDisabledDark: url('./img/logout-disabled-dark.svg')
$chevronBottomDark: url('./img/chevron-bottom-dark.svg')
$chevronBottomSelectedDark: url('./img/chevron-bottom-selected-dark.svg')
$eyeDark: url('./img/eye-dark.svg')
$eyeSelectedDark: url('./img/eye-selected-dark.svg')
$eyeHiddenDark: url('./img/eye-hidden-dark.svg')
$eyeHiddenSelectedDark: url('./img/eye-hidden-selected-dark.svg')
$helpDark: url('./img/help-dark.svg')
$helpSelectedDark: url('./img/help-selected-dark.svg')
$helpDisabled: url('./img/help-disabled.svg')
$helpDisabledDark: url('./img/help-disabled-dark.svg')
$message: url('./img/message.svg')
$messageSelected: url('./img/message-selected.svg')
$messageDisabled: url('./img/message-disabled.svg')
$messageDark: url('./img/message-dark.svg')
$messageSelectedDark: url('./img/message-selected-dark.svg')
$messageDisabledDark: url('./img/message-disabled-dark.svg')
$calendar: url('./img/calendar.svg')
$calendarSelected: url('./img/calendar-selected.svg')
$calendarDisabled: url('./img/calendar-disabled.svg')
$export: url('./img/export.svg')
$exportSelected: url('./img/export-selected.svg')
$exportDisabled: url('./img/export-disabled.svg')
$calendarDark: url('./img/calendar-dark.svg')
$calendarSelectedDark: url('./img/calendar-selected-dark.svg')
$calendarDisabledDark: url('./img/calendar-disabled-dark.svg')
$exportDark: url('./img/export-dark.svg')
$exportSelectedDark: url('./img/export-selected-dark.svg')
$exportDisabledDark: url('./img/export-disabled-dark.svg')

$close: url('./img/close.svg')
$closeSelected: url('./img/close-selected.svg')
$closeDisabled: url('./img/close-disabled.svg')
$closeDark: url('./img/close-dark.svg')
$closeSelectedDark: url('./img/close-selected-dark.svg')
$closeDisabledDark: url('./img/close-disabled-dark.svg')

$wordCloud: url('./img/word-cloud.svg')
$wordCloudDark: url('./img/word-cloud-dark.svg')

$connect: url('./img/connect.svg')
$connectSelected: url('./img/connect-selected.svg')
$connectDisabled: url('./img/connect-disabled.svg')
$connectDark: url('./img/connect-dark.svg')
$connectSelectedDark: url('./img/connect-selected-dark.svg')
$connectDisabledDark: url('./img/connect-disabled-dark.svg')

$slackIcon: url('./img/slack-icon.svg')
$celebrate: url('./img/celebrate.svg')
$celebrateDark: url('./img/celebrate-dark.svg')
$graph: url('./img/graph.svg')
$graphSelected: url('./img/graph-selected.svg')
$graphDisabled: url('./img/graph-disabled.svg')
$graphDark: url('./img/graph-dark.svg')
$graphSelectedDark: url('./img/graph-selected-dark.svg')
$graphDisabledDark: url('./img/graph-disabled-dark.svg')
$rocket: url('./img/rocket.svg')
$rocketSelected: url('./img/rocket.svg')
$rocketDisabled: url('./img/rocket-disabled.svg')
$rocketDark: url('./img/rocket-dark.svg')
$rocketSelectedDark: url('./img/rocket-selected-dark.svg')
$rocketDisabledDark: url('./img/rocket-disabled-dark.svg')
$support: url('./img/support.svg')
$supportSelected: url('./img/support-selected.svg')
$supportDisabled: url('./img/support-disabled.svg')
$supportDark: url('./img/support-dark.svg')
$supportSelectedDark: url('./img/support-selected-dark.svg')
$supportDisabledDark: url('./img/support-disabled-dark.svg')
$switcher: url('./img/switcher.svg')
$switcherSelected: url('./img/switcher-selected.svg')
$switcherDisabled: url('./img/switcher-disabled.svg')
$switcherDark: url('./img/switcher-dark.svg')
$switcherSelectedDark: url('./img/switcher-selected-dark.svg')
$switcherDisabledDark: url('./img/switcher-disabled-dark.svg')

////////////////////////////////////////
$details: url('./img/details.svg')
$detailsSelected: url('./img/details-selected.svg')
$detailsDisabled: url('./img/details-disabled.svg')
$detailsDark: url('./img/details-dark.svg')
$detailsSelectedDark: url('./img/details-selected-dark.svg')
$detailsDisabledDark: url('./img/details-disabled-dark.svg')

$mode: url('./img/mode.svg')
$modeDark: url('./img/mode-dark.svg')

$modeWhite: url('./img/mode-white.svg')

$inputExample: url('./img/input-example.svg')
$inputExample2: url('./img/input-example2.svg')
$inputExampleDark: url('./img/input-example-dark.svg')
$inputExample2Dark: url('./img/input-example2-dark.svg')

$avatarEmpty: url('./img/avatar-empty-selected.svg')
$avatarEmptyDark: url('./img/avatar-empty-selected-dark.svg')

$avatarMonochromeSupport: url('./img/avatar-monochrome-support.svg')

$confirmEmailSelected: url('./img/confirm-email-selected.svg')
$confirmEmailSelectedDark: url('./img/confirm-email-selected-dark.svg')

$moon: url('./img/moon.svg')
$sun: url('./img/sun.svg')

$messageSent: url('./img/message-sent.svg')
$messageSentDark: url('./img/message-sent-dark.svg')
$messageSentSuccess: url('./img/message-sent-success.svg')

$infoImg: url('./img/info.svg')
$infoImgDark: url('./img/info-dark.svg')

$conectWorkspace: url('./img/connect-workspace.svg')

$sunGreen: url('./img/sun-green.svg')
$moonRed: url('./img/moon-red.svg')

// $white: #fff
// $purpleDisabled: #9A8AB6 //#A395BD
// $purple: #3D1D75 //#503483//
// $black: #000
// $red: #E01E5A
// $outsideTheComponentWhiteThemeBackground: #F7F7F7
// $onhoverBackgroundButton: #333333
@font-face
  font-family: 'Roboto'
  src: $roboto format("truetype")
.tooltip
  display: flex
  flex-direction: column
  align-items: flex-start
  border-radius: 4px
  text-align: start
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  -o-user-select: none
  user-select: none
  cursor: pointer
  // color: 
  .tooltip-button
    margin: 0
    padding: 0
    font-family: Roboto
    font-style: normal
    font-weight: normal !important
    font-size: 12px
    line-height: 24px
    color: #3D1D75
    background: unset !important
    border: none !important
    &:hover
      color: #000
  .tooltip-button
    &.dark
      color: #BDBDBD !important
      &:hover
        color: #F2F2F2 !important
  hr
    width: 109%
    // color: #f2f2f2
    // background-color: #f2f2f2
    margin: 0 -8px
    display: block
    height: 1px
    border: 0
    border-top: 1px solid #E0E0E0
    // margin: 1em 0
    // padding: 0
    &.dark
      border-top: 1px solid #333333 !important
  .subtext
    color: #666666
.custom-checkbox label:before, .custom-checkbox label:before

  border: 1px solid #3D1D75 !important
  // border-color: #3D1D75 !important
.custom-checkbox input~label:after
  background: #3D1D75 linear-gradient(transparent,rgba(0,0,0,.05)) !important

.checked.custom-checkbox input~label:after
  background: #fff linear-gradient(transparent,rgba(0,0,0,.05)) !important

.ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before
  border-color: unset !important
.ui.checkbox input:focus~.box:before, .ui.checkbox input:focus~label:before
  // background: #fff
  border-color: unset !important
span
  font-family: 'Roboto' !important
h1, h2, h3, h4, h5
  font-weight: normal !important
  font-family: 'Roboto' !important
body
  font-family: 'Roboto' !important
  font-weight: normal !important
  &::after
    content: $logo $logoMini $blocks $blocksSelected $gear $gearSelected $gearDisabled $user $userSelected $userDisabled $logout $logoutSelected $chevronBottom $chevronBottomSelected $eye $eyeSelected $eyeHidden $eyeHiddenSelected $help $helpSelected $logoDark $logoMiniDark $blocksDark $blocksSelectedDark $gearDark $gearSelectedDark $gearDisabledDark $userDark $userSelectedDark $userDisabledDark $logoutDark $logoutSelectedDark $chevronBottomDark $chevronBottomSelectedDark $eyeDark $eyeSelectedDark $eyeHiddenDark $eyeHiddenSelectedDark $helpDark $helpSelectedDark $helpDisabledDark $message $messageSelected $messageDisabled $messageDark $messageSelectedDark $messageDisabledDark $calendar $calendarSelected $calendarDisabled $export $exportSelected $exportDisabled $calendarDark $calendarSelectedDark $calendarDisabledDark $exportDark $exportSelectedDark $exportDisabledDark $wordCloud $wordCloudDark $close $closeSelected $closeDisabled $closeDark $closeSelectedDark $closeDisabledDark $connect $connectSelected $connectDisabled $connectDark $connectSelectedDark $connectDisabledDark $celebrate $celebrateDark $graph $graphSelected $graphDisabled $graphDark $graphSelectedDark $graphDisabledDark $rocket $rocketSelected $rocketDisabled $rocketDark $rocketSelectedDark $rocketDisabledDark $support $supportSelected $supportDisabled $supportDark $supportSelectedDark $supportDisabledDark $switcher $switcherSelected $switcherDisabled $switcherDark $switcherSelectedDark $switcherDisabledDark $details $detailsSelected $detailsDisabled $detailsDark $detailsSelectedDark $detailsDisabledDark $mode $modeDark $modeWhite $inputExample $inputExample2 $inputExampleDark $inputExample2Dark $avatarEmpty $avatarEmptyDark $avatarMonochromeSupport $confirmEmailSelected $confirmEmailSelectedDark $moon $sun $messageSent $messageSentDark $messageSentSuccess $infoImg $infoImgDark $conectWorkspace $sunGreen $moonRed !important
    position: absolute
    width: 0
    height: 0
    overflow: hidden
    z-index: -1

  .ui.menu a.item:hover
    background-color: transparent
  .ui.inverted.dimmer
    background-color: rgba(34,34,34,0.2) !important

  .ui.solid-dimmer
    padding-top: 72px
    background-color: #fff !important
    &.dark
      background-color: #000 !important
      .ui.loader
        color: #fff !important
        &::after
          border-color: #fff transparent transparent !important
    .ui.loader
      color: #000 !important
      &::after
        border-color: #000 transparent transparent !important
  a.error,.error
    color: #E01E5A !important
  button
    cursor: pointer
    font-family: 'Roboto' !important
    &.error
      background: #E01E5A !important
      color: #fff !important
      &:hover
        background: #C71A51 !important
      &.disabled
        background: #EB8AA8 !important
  .disabled, disabled
    color: #9A8AB6 !important
    pointer-events: none !important
    a,div
      color: #9A8AB6 !important

  input::placeholder
    color: #9A8AB6 !important
  textarea::placeholder
    color: #9A8AB6 !important
  .close-container
    background-image: $close
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 24px
    height: 24px
    cursor: pointer
    &:hover, &.selected
      background-image: $closeSelected
    &.disabled
      background-image: $closeDisabled !important
      pointer-events: none

.App
  .no-data-to-display
    padding: 2rem 1.5rem 1.5rem 1.5rem
    text-align: center
    font-size: 14px
  .opened
    display: block
  .closed
    display: none
  .item-content
    // float: right
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    align-items: center
    text-align: center
    position: relative
    margin-bottom: 24px !important
    &.password
      margin-bottom: 20px !important
    input, .selection, .input,textarea
      // width: 200px !important
      // width: 100% !important
      border: none !important
      background: #F5F5F5 !important
      border-radius: 6px
      border: none
      float: left
    .user-email
      margin-left: 8px
      font-family: Roboto
      font-style: normal
      // font-weight: 500
      font-size: 14px
      line-height: 16px
    .pricing-plan-container
      margin-left: 8px
      justify-content: space-between
      flex-direction: row
      display: flex
      width: 100%
      div
        & :first-child
          margin-right: 10px
        &:nth-child(2)
          color: #666 !important
  // my own styles - check with Jecka
  textarea
    outline: none !important
    resize: none !important
    padding: 8px
    &:focus-visible, &:focus
      border: 1px solid #000000 !important
      color: #000000 !important
  .item-content input, .item-content .selection, .item-content textarea
    height: 40px
    border: 1px solid #503483 !important
    color: #503483 !important
    background: #fff !important
    border-radius: 6px !important
    &:hover
      border: 1px solid #333333 !important
      color: #333333 !important
    &:active, &:focus
      border: 1px solid #000000 !important
      color: #000000 !important
    & .disabled
      border: 1px solid #503483 !important
      color: #503483 !important
  .item-content i
    color: #503483 !important
    &:hover
      color: #333333 !important
    &:active, &:focus
      color: #000000 !important
    & .disabled
      color: #503483 !important
  .item-content.error input
    border: 1px solid #E01E5A !important
    &:focus
      border: 1px solid #E01E5A !important
      // color: #000000 !important
  // .custom-input
  //   background: #FFFFFF
  //   // border: 1px solid #503483
  //   box-sizing: border-box
  //   border-radius: 6px
  //   margin: 0 !important
  //   margin-bottom: 24px !important
  //   .default.text
  //     color: #503483
  // .custom-input
  //   &::focus
  //     border: 1px solid #000
  .email-error,.phone-error
    position: absolute
    top: 38px
    font-family: Roboto
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 16px
    display: flex
    align-items: center
    color: #E01E5A

  .ui.selection.active.dropdown
    .menu
      border-color: #503483 !important
    &:hover .menu
      border-color: #000000 !important
  .ui.active.selection.dropdown
    border-radius: 6px !important
    border-color: unset !important
  .ui.dropdown .menu .selected.item
    background: #fff
  .ui.selection.active.dropdown .menu
    border-color: #3D1D75 !important
    margin: 0 !important
    border-width: 1px !important
    border-top: none !important
    left: -1px !important
    top: 37px
    width: calc(100% - 1px) !important
    border: none !important
    border-radius: 6px
    .item
      border: none !important
      color: #3D1D75
      font-weight: 400
      &:hover
        background-color: #F7F7F7
        color: #333333
        border-radius: 6px !important
      &.disabled
        color: #BDBDBD
      &.active
        color: #3D1D75
        background-color: #fff// !important // HERE IS
        // font-weight: normal !important
        span
          color: #000 !important
        &:hover
          background-color: #F7F7F7 !important
          color: #333333
          border-radius: 6px !important
  .ui.selection.dropdown .menu>.item
    border: none !important
  .disabled
    pointer-events: none
  .ui.menu
    display: flex
    // margin: 0 !important
    border-radius: 0 !important
    .item
      padding: 4.5px 1.14285714em !important
      .item
        padding: 4.5px 8px !important
    i.icon
      margin: 0 !important
      width: unset !important
      height: unset !important

  .disable-links
    pointer-events: none
    color: #A395BD !important

  .none
    display: none

  // .text-bold
  //   font-weight: bold

  a
    color: #3D1D75 !important// #503483 !important
    font-size: 14px//12px
    line-height: 16px

    &:hover, &::selection
      color: #000000 !important
    &.disabled
      color: #A395BD !important

  // .ui.toggle.checkbox input:checked ~
  //   .box:before, label:before
  //     background-color: #503483 !important

  .rotated
    transform: rotate(180deg)
  .avatar-monochrome-support
    background-image: $avatarMonochromeSupport
    width: 32px
    height: 32px
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    margin: 0 8px 0 0
    padding: none
    pointer-events: none
    border-radius: 50%

  .logo-big-container
    background-image: $logo !important
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    // margin: 0
    // padding: 0
    // overflow: hidden
    width: 197px
    height: 48px
  .logo-medium-container
    div
      width: 132px !important
      height: 32px !important

  .logo-small-container
    background-image: $logoMini
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    // margin: 0
    // padding: 0
    // overflow: hidden
    width: 49px
    height: 24px
    position: relative
    .demo
      position: absolute
      top: 0px
      left: 50px
      text-transform: capitalize
      font-family: Roboto
      font-style: normal
      font-weight: bold
      font-size: 9px
      line-height: 10px
      /* identical to box height, or 111% */
      text-transform: uppercase
      color: #3D1D75

  .blocks-container
    background-image: $blocks
    margin-right: 4px !important
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    // margin: 0
    // padding: 0
    // overflow: hidden
    width: 24px
    height: 24px
    pointer-events: none
    &:hover, &.selected
      // background-image: $blocksSelected
      filter: grayscale(100%)
  .menu-itm
    &:hover
      .blocks-container
        // background-image: $blocksSelected
        filter: grayscale(100%)
  .active .blocks-container
    // , &:hover, &.selected
    // background-image: $blocksSelected
    filter: grayscale(100%)
  .menu-itm
    &:hover
      .blocks-container
        // background-image: $blocksSelected
        filter: grayscale(100%)

  .mode-container
    background-image: $modeWhite// $sun //$mode
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    // margin-right: 4px
    width: 14px //24px
    height: 14px //24px
    position: absolute
    left: 11px
    right: unset
    z-index: 5
    pointer-events: none
    margin: 0

  .gear-container
    background-image: $gear
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    // margin: 0
    // padding: 0
    // overflow: hidden
    width: 24px
    height: 24px
    pointer-events: none
    // &:hover, &.selected
    //   background-image: $gearSelected
  .menu-itm
    &:hover
      .gear-container
        // background-image: $gearSelected
        filter: grayscale(100%)
  
  .active .gear-container
    // background-image: $gearSelected
    filter: grayscale(100%)
  .disabled
    .gear-container
      background-image: $gearDisabled

  .user-container
    background-image: $user
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    // margin: 0
    // padding: 0
    // overflow: hidden
    width: 24px
    height: 24px
    pointer-events: none
    // &:hover, &.selected
    //   background-image: $userSelected
  .menu-itm
    &:hover
      .user-container
        // background-image: $userSelected
        filter: grayscale(100%)

  .active .user-container
    // background-image: $userSelected
    filter: grayscale(100%)
  .disabled
    .user-container
      background-image: $userDisabled

  .logout-container
    background-image: $logout
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    // margin: 0
    // padding: 0
    // overflow: hidden
    width: 24px
    height: 24px
    pointer-events: none
    &:hover, &.selected
      // background-image: $logoutSelected
      filter: grayscale(100%)
  .menu-itm
    &:hover
      .logout-container
        // background-image: $logoutSelected
        filter: grayscale(100%)
  .active .logout-container
    // background-image: $logoutSelected
    filter: grayscale(100%)

  .chevron-container
    background-image: $chevronBottom
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    // margin: 0
    // padding: 0
    // overflow: hidden
    width: 24px
    height: 24px
    &:hover, &.selected
      background-image: $chevronBottomSelected
  .active .chevron-container
    background-image: $chevronBottomSelected

  .pwrd-icon
    background-image: $eye
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    // margin: 0
    // padding: 0
    // overflow: hidden
    width: 24px
    height: 24px
    cursor: pointer
    &:hover, &.selected
      background-image: $eyeSelected

  .pwrd-icon-hidden
    background-image: $eyeHidden
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    // margin: 0
    // padding: 0
    // overflow: hidden
    width: 24px
    height: 24px
    &:hover, &.selected
      background-image: $eyeHiddenSelected

  .help-contaier
    cursor: pointer
    background-image: $help
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    // margin: 0
    // padding: 0
    // overflow: hidden
    width: 24px
    height: 24px
    &:hover, &.selected, &.active
      // background-image: $helpSelected
      filter: grayscale(100%)
    &.disabled
      background-image: $helpDisabled

  .messages-container
    background-image: $message
    margin-right: 4px !important
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    // margin: 0
    // padding: 0
    // overflow: hidden
    width: 24px
    height: 24px
    &:hover, &.selected
      background-image: $messageSelected
  .active .messages-container
    // , &:hover, &.selected
    background-image: $messageSelected
  .messages-container .disabled
    background-image: $messageDisabled
  .menu-itm
    &:hover
      .messages-container
        background-image: $messageSelected

  .connect-container
    background-image: $connect
    margin-right: 4px !important
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    // margin: 0
    // padding: 0
    // overflow: hidden
    width: 24px
    height: 24px
    &:hover, &.selected
      // background-image: $connectSelected
      filter: grayscale(100%)
  .menu-itm
    &:hover
      .connect-container
        // background-image: $connectSelected
        filter: grayscale(100%)
  
  .active .connect-container
    // background-image: $connectSelected
    filter: grayscale(100%)
  .disabled
    .connect-container
      background-image: $connectDisabled

  .calendar-container
    background-image: $calendar
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    margin-right: 4px
    width: 18px
    height: 18px
    margin-bottom: 3px
    &:hover, &.selected
      background-image: $calendarSelected
    &.disabled
      background-image: $calendarDisabled
    // .active .calendar-container
    //   background-image: $calendarSelected

  .export-container
    background-image: $export
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 24px
    height: 24px
    cursor: pointer
    &:hover, &.selected
      // background-image: $exportSelected
      filter: grayscale(100%)
    &.disabled
      background-image: $exportDisabled !important
      pointer-events: none
  .disabled
    .export-container
      background-image: $exportDisabled !important
      pointer-events: none

    // $slackIcon: url('./img/slack-icon.svg')

  .slack-container
    background-image: $slackIcon
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 24px
    height: 24px

  .wordcloud-contaier
    background-image: $wordCloud
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 340px
    height: 182px
    pointer-events: none

  .celebrate-container
    background-image: $celebrate
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 32px
    height: 32px
    margin-bottom: 4px

  .graph-container
    background-image: $graph
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 24px
    height: 24px
    &:hover, &.selected
      background-image: $graphSelected
    &.disabled
      background-image: $graphDisabled

  .rocket-container
    background-image: $rocket
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 24px
    height: 24px
    &:hover, &.selected
      background-image: $rocketSelected
    &.disabled
      background-image: $rocketDisabled

  .support-container
    background-image: $support
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 24px
    height: 24px
    &:hover, &.selected
      background-image: $supportSelected
  .disabled
    .support-container
      background-image: $supportDisabled

  .switcher-container
    background-image: $switcher
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 24px
    height: 24px
    &:hover, &.selected
      background-image: $switcherSelected
  .disabled
    .switcher-container
      background-image: $switcherDisabled

  .details-container
    background-image: $details
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 24px
    height: 24px
    &:hover, &.selected
      background-image: $detailsSelected
  .disabled
    .details-container
      background-image: $detailsDisabled

  .input-example-container
    background-image: $inputExample //$inputExample2 $inputExampleDark $inputExample2Dark
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 376px
    height: 40px

  .input2-example-container
    background-image: $inputExample2 //$inputExample2 $inputExampleDark $inputExample2Dark
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 376px
    height: 69px

  .avatar-empty-container
    background-image: $avatarEmpty
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 32px !important
    height: 32px !important

  .confirm-email-container
    background-image: $confirmEmailSelected
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 32px
    height: 32px
    margin-bottom: 4px

  .message-sent
    background-image:  $messageSent
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 32px
    height: 32px
    margin-bottom: 4px
  .message-sent-success
    background-image:  $messageSentSuccess
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 24px
    height: 24px
    // margin-bottom: 4px

  .info-container
    background-image:  $infoImg
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 24px
    height: 24px
    // margin-bottom: 4px

  .connect-workspace
    background-image:  $conectWorkspace
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    // width: calc( 560px - 64px)
    width: 560px
    // max-width: 560px
    // height: calc( 256px - 64px )
    height: 256px 
    // max-height: 256px
    border-radius: 6px
    // margin: 32px
    // margin-bottom: 4px
    margin-top: 16px

  .user-activity-status
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    width: 24px
    height: 24px
    &.active    
      background-image: $sunGreen
    &.inactive
      background-image: $moonRed

  &.dark
    input::placeholder
      color: #bdbdbd !important
    textarea::placeholder
      color: #bdbdbd !important
    .disabled
      color: #686868 !important
      a,div
        color: #686868 !important
    button
      &.error
        background: #E01E5A !important
        // color: #bdbdbd
        &:hover
          background: #C71A51 !important
        &.disabled
          background: #7A1836 !important
    .email-error,.phone-error
      color: #E01E5A !important
    .item-content.error input
      border: 1px solid #E01E5A !important
      &:focus
        border: 1px solid #E01E5A !important
    .item-content .selection
      &.disabled
        // border: 1px solid #686868 !important
        color: #686868 !important
    input, .input,.item-content input, .item-content .selection,.profile-label,textarea, .item-content a
      background: #222222 !important
      color: #bdbdbd !important
    .item-content input, .item-content .selection, .item-content textarea, .item-content a
      border: 1px solid #BDBDBD !important
      &:hover, &:active, &:focus
        border: 1px solid #f2f2f2 !important
        color: #f2f2f2 !important
      &.disabled
        border: 1px solid #686868 !important
        color: #686868 !important
    .item-content i
      color: #bdbdbd !important
      &:hover, &:active, &:focus
        color: #f2f2f2 !important
      &.disabled
        color: #686868 !important
    input,
    &::placeholder
      color: #BDBDBD !important
    textarea,
    &::placeholder
      color: #BDBDBD !important
    .ui.selection.dropdown:focus
      border-color: unset !important
    .ui.selection.active.dropdown
      .menu
        border-color: #F2F2F2 !important
      &:hover .menu
        border-color: #ffffff !important
    .ui.selection.visible.dropdown>.text:not(.default)
      color: #f2f2f2 !important

    .ui.dropdown .menu .selected.item
      background: #222
    .ui.selection.active.dropdown .menu
      .active .selected
        background: #333333 !important
      .item
        border: none !important
        color: #3D1D75
        font-weight: medium
        .text
          color: #BDBDBD !important
        &:hover
          background-color: #333333
          color: #f2f2f2
          border-radius: 6px !important
          .text
            color: #f2f2f2 !important
        &.disabled
          color: #BDBDBD
        &.active
          background-color: #222 !important
          color: #fff !important
          // background-color: #fff !important
          // font-weight: normal !important
          span
            color: #fff !important

          &:hover
            background-color: #333333 !important
            color: #0f0505
            border-radius: 6px !important

      .selected .item
        .text
          color: #fff !important
          /////////////////////////     
          border-color: #ffffff !important
    .logo-big-container
      background-image: $logoDark !important

    .logo-small-container
      background-image: $logoMiniDark
      .demo
        color: #BDBDBD

    .blocks-container
      background-image: $blocksDark
      &:hover, &.selected
        background-image: $blocksSelectedDark
    .menu-itm
      &:hover
        .blocks-container
          background-image: $blocksSelectedDark
    .active .blocks-container
      background-image: $blocksSelectedDark
    .menu-itm
      &:hover
        .blocks-container
          background-image: $blocksSelectedDark

    .mode-container
      background-image: $moon //$modeDark
      left: unset
      right: 11px

    .gear-container
      background-image: $gearDark
      &:hover, &.selected
        background-image: $gearSelectedDark
    .menu-itm
      &:hover
        .gear-container
          background-image: $gearSelectedDark
    .active .gear-container
      background-image: $gearSelectedDark
    .disabled
      .gear-container
        background-image: $gearDisabledDark

    .user-container
      background-image: $userDark
      &:hover, &.selected
        background-image: $userSelectedDark
    .menu-itm
      &:hover
        .user-container
          background-image: $userSelectedDark
    .active .user-container
      background-image: $userSelectedDark
    .disabled
      .user-container
        background-image: $userDisabledDark

    .logout-container
      background-image: $logoutDark
      &:hover, &.selected
        background-image: $logoutSelectedDark
    .menu-itm
      &:hover
        .logout-container
          background-image: $logoutSelectedDark
    .active .logout-container
      background-image: $logoutSelectedDark

    .chevron-container
      background-image: $chevronBottomDark
      &:hover, &.selected
        background-image: $chevronBottomSelectedDark
    .active .chevron-container
      background-image: $chevronBottomSelectedDark

    .pwrd-icon
      background-image: $eyeDark
      &:hover, &.selected
        background-image: $eyeSelectedDark

    .pwrd-icon-hidden
      background-image: $eyeHiddenDark
      &:hover, &.selected
        background-image: $eyeHiddenSelectedDark

    .help-contaier
      background-image: $helpDark
      &:hover, &.selected, &.active
        background-image: $helpSelectedDark
      &.disabled
        background-image: $helpDisabledDark

    .messages-container // $message $messageSelected $messageDisabled $messageDark $messageSelectedDark $messageDisabledDark
      background-image: $messageDark
      &:hover, &.selected
        background-image: $messageSelectedDark
    .active .messages-container
      // , &:hover, &.selected
      background-image: $messageSelectedDark
    .messages-container .disabled
      background-image: $messageDisabledDark
    .menu-itm
      &:hover
        .messages-container
          background-image: $messageSelectedDark
    .connect-container
      // background-color: transparent !important
      background-image: $connectDark
      &:hover, &.selected
        background-image: $connectSelectedDark
    .active .connect-container
      // , &:hover, &.selected
      background-image: $connectSelectedDark
    .disabled
      .connect-container
        background-image: $connectDisabledDark
    .menu-itm
      &:hover
        .connect-container
          background-image: $connectSelectedDark
    .calendar-container
      background-image: $calendarDark
      &:hover, &.selected
        background-image: $calendarSelectedDark
    .calendar-container .disabled
      background-image: $calendarDisabledDark
    .export-container
      background-image: $exportDark
      &:hover, &.selected
        background-image: $exportSelectedDark
      &.disabled
        background-image: $exportDisabledDark !important
    .disabled
      .export-container
        background-image: $exportDisabledDark !important

    .close-container
      background-image: $closeDark !important
      &:hover, &.selected
        background-image: $closeSelectedDark !important
      &.disabled
        background-image: $closeDisabledDark !important
        pointer-events: none
    //$celebrate $celebrateDark  
    .celebrate-container
      background-image: $celebrateDark
    .graph-container
      background-image: $graphDark
      &:hover, &.selected
        background-image: $graphSelectedDark
      &.disabled
        background-image: $graphDisabledDark
    .rocket-container
      background-image: $rocketDark
      &:hover, &.selected
        background-image: $rocketSelectedDark
      &.disabled
        background-image: $rocketDisabledDark
    .support-container
      background-image: $supportDark
      &:hover, &.selected
        background-image: $supportSelectedDark
      &.disabled
        background-image: $supportDisabledDark
    .switcher-container
      background-image: $switcherDark
      &:hover, &.selected
        background-image: $switcherSelectedDark
      &.disabled
        background-image: $switcherDisabledDark
    .details-container
      background-image: $detailsDark
      &:hover, &.selected
        background-image: $detailsSelectedDark
      &.disabled
        background-image: $detailsDisabledDark

    .input-example-container
      background-image: $inputExampleDark
    .input2-example-container
      background-image: $inputExample2Dark

    .wordcloud-contaier
      background-image: $wordCloudDark
      //$avatarEmpty $avatarEmptyDark
    .avatar-empty-container
      background-image: $avatarEmptyDark //$inputExample2
    .confirm-email-container
      background-image: $confirmEmailSelectedDark
    
    .message-sent
      background-image: $messageSentDark

    .info-container
      background-image: $infoImgDark
    a
      color: #f2f2f2 !important
      &.error
        color: #E01E5A !important
    .checkbox label
      color: #BDBDBD !important
      &:before
        background: #222222 !important
        border: 1px solid #bdbdbd !important
      &:after
        color: #bdbdbd !important

    .myModal //.modal-container
      background: #222222 !important
      // .modalTrigger
      //   color: #bdbdbd

    .disable-links
      color: #686868 !important

.ui.popup
  border-width: 0px !important
  text-align: start !important
  z-index: 10 !important
  font-weight: normal !important
  // background-color: #fff !important
  padding: 8px !important
  border-radius: 6px !important
  box-shadow: 0 16px 16px -16px rgb(0 0 0 / 10%), -16px 0 16px -16px rgb(0 0 0 / 10%), 16px 0 16px -16px rgb(0 0 0 / 10%) !important
  .content
    color: inherit !important
.dark 
  .ui.popup
    background-color: #222 !important
    box-shadow: 0 16px 16px -16px rgb(128 128 128 / 10%), -16px 0 16px -16px rgb(128 128 128 / 10%), 16px 0 16px -16px rgb(128 128 128 / 10%) !important

$closeDark: url('./img/close-dark.svg')
$logo: url('./img/logo.svg')
$logoDark: url('./img/logo-dark.svg')

.slackConnectModal
    border: none !important
    border-radius: 8px !important
    width: 600px !important
    .header
        border-bottom: none !important
        font-size: 16px !important
        border-radius: 6px 6px 0 0 !important
        padding: 24px 24px 20px 24px !important
    .content
        font-size: 14px !important
        padding: 0 24px !important
        .modal-logo
            font-size: 16px
        .slackConnectContent
            justify-content: center
            display: flex
            flex-direction: column
            align-items: center
            text-align: center
            a
                margin: 24px 0
                font-weight: normal
                font-size: 14px
                line-height: 24px
                color: #3D1D75
            .bottom-link
                margin-bottom: 64px

            .logo-big-container
              background-image: $logo
              background-size: cover
              -webkit-background-size: cover
              -moz-background-size: cover
              -o-background-size: cover
              // margin: 0
              // padding: 0
              // overflow: hidden
              width: 197px
              height: 48px

            .logo-big-container, .slack-conection-container, p, .login-opts-divider, input
                margin-bottom: 24px
            .login-opts-divider
                display: flex
                width: 100%
                flex-direction: row
                justify-content: center
                align-items: flex-end
                margin-top: 22px
                margin-bottom: 24px
                color: #D8D8D8
            hr
                width: 45%
                height: 1px
                border: 0
                border-top: 1px solid #D8D8D8
        .not-admin-container
            display: flex
            flex-direction: column

    .slack-conection-container
        display: flex !important
        justify-content: space-between !important
        width: 21.1% !important
        flex-direction: row !important
        // div
        //     margin: 0 6px
    .copy-input input
        margin-bottom: 0px !important

    .copy-input,.slack-btn
        width: 352px !important
    a
        cursor: pointer
    p,a
        font-size: 14px
        text-align: left
        ////

    .custom-input
        background: #FFFFFF
        border: 1px solid #503483
        box-sizing: border-box
        border-radius: 6px
        margin: 0 !important
        margin-bottom: 24px !important
        .default.text
            color: #503483
    .custom-input
    &::focus
        border: 1px solid #000

    .custom-input input, input
        border: 1px solid #503483 !important
        color: #503483 !important
        background: #fff !important
        border-radius: 6px !important
        &:hover
            border: 1px solid #333333 !important
            color: #333333 !important
        &:active, &:focus
            border: 1px solid #000000 !important
            color: #000000 !important
        & .disabled
            border: 1px solid #503483 !important
            color: #503483 !important

        ////
    .actions
        background: #fff !important
        border-top: none !important
        border-radius: 0 0 6px 6px !important
        padding: 24px !important
        &.center
            display: flex
            justify-content: center
    .close-container
        position: absolute
        top: 24px !important
        right: 24px !important
        color: #3D1D75 !important
        &:hover
            color: #333333 !important
    .button
        background: #3D1D75 !important
        color: #fff !important
        font-size: 14px !important
        height: 40px im !important
        padding: 0.93em 1.5em 0.93em !important
        &.wide
            width: 208px
            margin-left: 0 !important
            margin: 0 auto !important
        &:hover
            background: #333333 !important

    .slack-btn
        display: flex !important
        justify-content: center
        text-align: center
        align-items: center
        background: #fff
        border: #503483 2px solid !important
        padding: 6px 1.5em 6px !important
        &:hover
            border: #000 2px solid !important
            & span
                color: #fff !important
        img
            margin-right: 10px
    .slack-btn
        background: #fff !important
        border: #503483 2px solid !important
    span
        color: #503483
    .invitationForm
        .big-button
            width: 100%
            margin-top: 24px
    .slackInvitationsContainer
        display: flex
        flex-direction: column
        flex-wrap: nowrap
        text-align: start
        width: 352px
        height: 164px
        border: 1px solid #3D1D75
        box-sizing: border-box
        border-radius: 6px
        overflow-y: auto
        .user-holder
            &:hover
                background: #F7F7F7
            .ui.checkbox input:checked:focus~label:before
                border: 1.5px solid #000
            .ui.checkbox
                margin: 12px 8px
                display: flex
                justify-content: flex-start
                text-align: start
                align-items: flex-start
                label:before
                    border: 1.5px solid #3D1D75
                input
                    border: 1.5px solid #3D1D75
                    border-radius: 2px
                    padding: 0
                    margin: 0
                label
                    font-family: Roboto
                    font-style: normal
                    // font-weight: 500
                    font-size: 14px !important
                    line-height: 20px !important
    &.dark
        background: #222222 !important
        ::-webkit-scrollbar-thumb, .ui .modal ::-webkit-scrollbar-thumb
            cursor: pointer
            background: #F2F2F2 !important
        .logo-big-container
            background-image: $logoDark !important

        .ui.modal
            background: #222222 !important
        .actions
            background: #222222 !important
        .close
            color: #BDBDBD !important
        
        .close-container
            background-image: $closeDark !important
        a
            color: #BDBDBD !important
        .slackConnectModal,.content,.header,.slackConnectContent
            background: #222222 !important
            color: #bdbdbd !important
        .button
            background: #BDBDBD !important
            color: #333333 !important
            &:hover
                background: #f2f2f2 !important
            & .disabled
                background: #686868 !important
        .slack-btn
            background: #222222 !important
            &:hover
                border-color: #f2f2f2 !important
                & span
                    color: #333333 !important
        .slack-btn
            border: 2px solid #BDBDBD !important
            span
                color: #BDBDBD !important
        .custom-input input, input
            border: 1px solid #bdbdbd !important
            color: #bdbdbd !important
            background: #222222 !important
            border-radius: 6px !important
            &:hover
                border: 1px solid #F2F2F2 !important
                color: #F2F2F2 !important
            &:active, &:focus
                border: 1px solid #F2F2F2 !important
                color: #F2F2F2 !important
            & .disabled
                border: 1px solid #686868 !important
                color: #686868 !important
        .slackInvitationsContainer
            border: 1px solid #f2f2f2

            .user-holder
                &:hover
                    background: #F7F7F7
                .ui.checkbox input:checked:focus~label:before
                    border: 1.5px solid #f2f2f2
                .ui.checkbox
                    margin: 12px 8px
                    display: flex
                    justify-content: flex-start
                    text-align: start
                    align-items: flex-start
                    label:before
                        border: 1.5px solid #f2f2f2 !important
                    input
                        border: 1.5px solid #f2f2f2 !important
                        border-radius: 2px
                        padding: 0
                        margin: 0
                    label
                        font-family: Roboto
                        font-style: normal
                        // font-weight: 500
                        font-size: 14px !important
                        line-height: 20px !important

.RecoverPasswordSuccess
  background-color: #F2F2F2
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100vh
  flex-direction: column

  .logo-big-container
    width: 132px
    height: 32px
  .main-content
    width: 640px
    height: 420px
    background: #FFFFFF
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1)
    border-radius: 6px
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap
    flex-direction: column
    position: relative
  .content
    p
      margin-bottom: 54px !important

  .signup-link-container
    position: absolute
    top: 24px
    right: 24px
    font-size: 12px
    line-height: 16px
    // margin-right: 8px
    & span
      font-size: 12px
      line-height: 16px
      margin-right: 8px !important

  .sign-in-container
    margin-bottom: 32px

  .main-registration-form
    // position: absolute
    // top: 128px
    // min-width: 352px
    // margin-bottom: 64px
    min-width: 352px
    display: flex
    flex-direction: column
    justify-content: center
    text-align: center
    align-items: center
    width: 100

  .pass-recovery-header
    margin-bottom: 32px
    text-align: center
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 24px
    line-height: 24px

  .item-content
    width: 100%
  .celebrate-container   
    margin: 0 auto 4px auto

  .big-button
    width: 352px
    background: #503483 !important
    color: #fff !important
    border-radius: 6px
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    &:hover
      background: #000 !important
    & .disabled
      background: #A395BD !important

  .item-content
    background: #FFFFFF
    // border: 1px solid #503483
    box-sizing: border-box
    border-radius: 6px

    input
      border: none

  .pwrd-icon, .pwrd-icon-hidden
    position: absolute
    top: 7px
    right: 7px

  .login-link-container
    margin-top: 10px
    margin-left: 1px
    span
      color: #333333
      margin-right: 10px

  .password
    width: 100%
    position: relative
  .PasswordStrengthBar
    top: 42px !important
    width: 96% !important
    position: absolute !important
    left: 2% !important
    p
      display: none !important

// DARK
.dark

  .RecoverPasswordSuccess
    background-color: #131313

    .big-button
      // width: 352px
      // background: #e2b6b6
      color: #333333 !important
      background: #BDBDBD !important
      &:hover
        background: #f2f2f2 !important
      & .disabled
        background: #686868 !important
      span
        color: #333333 !important

    .signup-link-container span
      color: #F2F2F2 !important

    .main-content
      background: #222222
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1)
      span
        color: #333333

    .item-content
      background: #505050
      border: 1px solid #505050

    .item-content
      background: #222222 !important
      input
        border-color: #BDBDBD !important
        background: #222222 !important
        color: #fff !important
        &::selection, &:focus
          border-color: #F2F2F2 !important
          color: #fff !important

    .login-link-container
      span
        color: #bdbdbd !important

// .user-data-table
//   width: 408px !important// 98% !important

.customTable
  // max-height: 100%
  min-height: 80px
  overflow-y: auto // hidden
  overflow-x: hidden // auto
  // font-weight: 400
  // max-height: 50vh
  margin: 8px 8px 8px 8px//was padding: 8px 8px 8px 8px
  &.short
    max-height: 35vh
  &.long
    max-height: 76vh
  &.individual
    tbody tr td:nth-child(1), thead tr th:nth-child(1)
      text-align: start !important
    tbody tr td:nth-child(2), tbody tr td:nth-child(3), thead tr th:nth-child(2), thead tr th:nth-child(3)
      text-align: end !important// .customTable .ui.table td:nth-of-type(2) {
    tbody tr td:nth-child(1)
      width: 100px !important
      padding: 0 0 0 8px !important
    tbody tr td:nth-child(2),tbody tr td:nth-child(3),.ui.table td:nth-of-type(2)
      padding: 8px 15px 8px 4px !important
      min-width: 50px !important
      max-width: 50px !important
      width: 50px !important
  .ui.table td
    padding: 0px !important
    -webkit-user-select: none !important
    -o-user-select: none
    user-select: none
    text-overflow: ellipsis
    overflow: hidden
    height: 1.2em
    white-space: nowrap
    &.avatar-container
      width: 24px !important
      padding: 0 0 0 8px !important
    &:nth-of-type(2)
      padding: 4px 4px 4px 4px !important
      min-width: 90px
      max-width: 90px
      width: 90px
      // &:hover
      //   overflow: visible
      //   white-space: normal
      //   // height: auto
    &:nth-of-type(3)
      min-width:50px
      max-width: 50px
      width: 50px
    &:nth-of-type(4)
      padding-right: 12px !important
      min-width: 60px
      max-width: 60px
      width: 60px
    &:nth-of-type(5)
      padding-right: 15px !important
      min-width: 84px
      max-width: 84px
      width: 84px
    .user-avatar
      width: 24px
      height: 24px
      border-radius: 50%
      margin-top: 8px
  thead
    display: table !important
    width: 100% 
    position: sticky
    top: 0
  .table-body-wrapper
    // height: 64vh !important
    max-height: calc( 100% - 40px ) !important//64vh
    overflow-y: auto !important
  div.table-body-wrapper
    overflow-x: clip
  tbody
    display: table !important
    width: 99%//96% 
    // display: block !important
  .ui.table thead th
    background-color: #F7F7F7
    padding: 8px .78571429em !important
    border-bottom: none !important
    font-weight: normal !important
  .ui.table
    border: none !important
  .table
    // width: 408px !important// 98% !important
    margin: 0 auto !important

  .ui.table thead tr:first-child>th:first-child
    border-radius: 6px 0 0 0
    width: 50%
  .ui.table thead tr:first-child>th:last-child
    border-radius: 0 6px 0 0

  th
    color: #666666 !important
    &:not(:first-child)
      text-align: center !important

  tbody
    pointer-events: all
    .selected-row
      background: #f4aaaa !important
    tr
      cursor: pointer !important
      td
        -webkit-user-select: none
        -khtml-user-select: none
        -moz-user-select: none
        -ms-user-select: none
        -o-user-select: none
        user-select: none
        text-overflow: ellipsis
        overflow: hidden
        height: 1.2em
        white-space: nowrap

        // max-width: 1px
        // font-weight: normal
        // pointer-events: none
        // &:hover, &:click
        //   background: #da4a43 !important
        &:nth-child(4),&:nth-child(5)//&:nth-child(3),//:not(:first-child)
          text-align: end !important
          // padding-right: 5px
          // width: 25%
        &:nth-child(2),&:nth-child(3)
          text-align: start !important
          padding-left: 0 !important
          // width: 100px
          // width: 100%
          // color: #3D1D75

    .active-node
      background: #E2DDEA !important
      color: #000
      font-weight: normal !important
      td
        // font-weight: bold
        &:nth-child(2)
          color: #000


  .table-header
    // margin: 0
    // padding: 0
    // position: relative
    text-align: center
    justify-content: center
    align-items: center
    display: inline-flex !important
    cursor: pointer
    // flex-direction: row !important
    // flex-wrap: nowrap !important
    // justify-content: center
    // align-items: center
    &:nth-child(1)
      color: #333333
    span, div
      font-size: 12px !important
      margin: 0
      padding: 0
  .arrow-bottom, .arrow-top
    width: 24px
    height: 24px
    background-size: cover
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    margin-right: none
    padding: none
    // position: absolute
    // right: 0
  .arrow-bottom
    background-image: url('../../img/arrow.svg')
    &:hover, &.selected
      background-image: url('../../img/arrow-selected.svg')
    &.disabled
      background-image: url('../../img/arrow-disabled.svg')
  .arrow-top
    transform: rotate(180deg)
    background-image: url('../../img/arrow.svg')
    &:hover, &.selected
      background-image: url('../../img/arrow-selected.svg')
    &.disabled
      background-image: url('../../img/arrow-disabled.svg')

  .no-data-container
    padding: 24px 0 8px 0
    font-family: Roboto
    font-style: normal
    // font-weight: normal
    font-size: 12px
    line-height: 16px
    /* identical to box height, or 133% */
    text-align: center
    color: #666666

  .rounds-container
    display: flex
    flex-direction: row
    text-align: center
    align-items: center
    // margin: 24px 0 0 0
    cursor: pointer
    .round
      width: 6px
      height: 6px
      border: 1.5px solid #9A8AB6
      border-radius: 50%
      margin: 0 1.5px
      &.active
        background: #000000
        border: 1.5px solid #000000
.dark
  .customTable .ui.table thead th
    color: #F2F2F2 !important
    background: #333333 !important
    padding: 8px .78571429em
  .table-header
    &:nth-child(1)
      color: #ffffff
  .customTable
    th
      color: #F2F2F2 !important
      background: #333333 !important
    tbody
      tr
        td
          border-color: #333333 !important
          &:nth-child(2)
            color: #BDBDBD
      .active-node
        background: #333333 !important
        td
          color: #f2f2f2 !important
    .rounds-container
      .round
        border: 1.5px solid #686868
        &.active
          background: #F2F2F2
          border: 1.5px solid #F2F2F2

    .arrow-bottom
      background-image: url('../../img/arrow-dark.svg')
      &:hover, &.selected
        background-image: url('../../img/arrow-selected-dark.svg')
      &.disabled
        background-image: url('../../img/arrow-disabled-dark.svg')
    .arrow-top
      background-image: url('../../img/arrow-dark.svg')
      &:hover, &.selected
        background-image: url('../../img/arrow-selected-dark.svg')
      &.disabled
        background-image: url('../../img/arrow-disabled-dark.svg')


        
@media screen and  (max-width: 767px)
  .customTable
    &.short
      max-height: 27vh

        
@media screen and  (max-height: 800px)
  .customTable
    &.short
      max-height: 20vh

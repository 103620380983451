$googleChatBtn: url('../../img/google-chat-btn.svg')
$googleMeetBtn: url('../../img/google-meet-btn.svg')
$googleWorkspaceBtn: url('../../img/google-workspace-btn.svg')
$mattermostBtn: url('../../img/mattermost-btn.svg')
$microsoftTeamsBtn: url('../../img/microsoft-teams-btn.svg')
$office365Btn: url('../../img/office-365-btn.svg')
$otherBtn: url('../../img/other-btn.svg')
$serverBtn: url('../../img/server-btn.svg')
$signalBtn: url('../../img/signal-btn.svg')
$skypeBtn: url('../../img/skype-btn.svg')
$slackBtn: url('../../img/slack-btn.svg')
$telegramBtn: url('../../img/telegram-btn.svg')
$whatsAppBtn: url('../../img/whats-app-btn.svg')
$zoomBtn: url('../../img/zoom-btn.svg')
  
.Profile
  display: flex
  justify-content: center
  align-items: center
  background-color: #F2F2F2
  .profile-label
    font-weight: 700
  a
    font-size: 12px !important
  .profile-buttons-container
    display: flex
    position: relative
    // position: relative
    // left: -60px !important
    .big-button
      width: 96px
      height: 40px
      margin: 0 !important
    .borderless-button
      height: 40px
      margin-left: 8px
      margin-right: 32px
    
    .success-message
      position: absolute
      right:  30px
      top: 0
      height: 40px
      display: flex
      justify-content: center
      text-align: center
      align-items: center
    .success-message-consent
      position: absolute
      right:  30px
      top: 0
      height: 40px
      display: flex
      justify-content: center
      text-align: center
      align-items: center
  .password-buttons-container
    display: flex
    .big-button
      width: 96px
      height: 40px
    .borderless-button
      margin-left: 8px
      margin-right: 32px
  .email-buttons-container
    display: flex
    flex-direction: row
    margin: 24px 0
    .big-button
      width: 202px
      height: 40px
    .borderless-button
      margin-left: 8px
      margin-right: 32px
  .email-error
    color: #E01E5A !important

  .ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before, .ui.toggle.checkbox input:checked~label:before
    background-color: #503483 !important
  .ui.toggle.checkbox label
    &:hover
      &::before
        background: #3D1D75
    &::before
      width: 38px
      height: 18px
      background: #BDBDBD

    &::after
      width: 15px
      height: 15px
      top: 1.5px
      left: 1.5px
      box-shadow: none
  .ui.toggle.checkbox input:checked~label:after
    left: 21.5px
    box-shadow: none
  .ui.active.selection.dropdown
    border-radius: 6px !important
  .ui.selection.active.dropdown .menu
    margin: 0 !important
    border-width: 1px !important
    border-top: none !important
    left: -1px !important
    top: 37px
    width: calc(100% - 1px) !important
    border: none !important
    border-radius: 6px
    .item
      border: none !important
      color: #3D1D75
      &:hover
        background-color: #F7F7F7
        color: #000
        border-radius: 6px !important
      &.disabled
        color: #BDBDBD
      &.active
        background-color: #fff !important
        // font-weight: normal !important
        span
          color: #000 !important

  .ui.selection.dropdown .menu>.item
    border: none !important

  .profile-wrapper
    margin-top: 0 // 24px
    padding: 0 24px
    width: 576px
    /* height: 208px;
    background: #FFFFFF
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1)
    border-radius: 6px
    display: flex
    /* justify-content: center;
    align-items: center
    flex-direction: column
    position: relative
    justify-content: space-evenly

    .profile-label
      position: absolute
      left: 24px
      top: 24px
      font-family: 'Roboto'
      font-style: normal
      // font-weight: bold
      font-size: 16px
      line-height: 24px
      // text-transform: uppercase
      color: #000000

    .profile-content
      display: flex
      flex-direction: row
      justify-content: center
      align-items: flex-start//center
      text-align: start
      white-space: normal
      flex-wrap: nowrap
      margin: 24px//margin-bottom: 24px
      width: 100%
      &.flex-column
        flex-direction: column

      &:nth-of-type(1)
        margin-top: 0 !important
      &:nth-of-type(2)
        margin-top: 0 !important
      &:nth-of-type(3)
        margin-top: 0 !important
      &:nth-of-type(4)
        margin-top: 0 !important
      &:nth-of-type(5)
        margin-top: 0 !important
      &:last-of-type
        margin-bottom: 0!important

    .content-box
      // margin: 0 auto
      margin-top: 72px
      margin-bottom: 24px
      // width: calc(100% - 200px)
      // width: 528px
      width: 100%
      display: flex
      flex-direction: column
      justify-content: space-around
      align-items: center
      white-space: normal
      flex-wrap: nowrap

    .item-label
      width: 30%
      // margin-top: 8px
      height: 40px
      float: left
      font-family: 'Roboto'
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 24px
      display: flex
      align-items: center
      text-transform: capitalize
      white-space: normal

      /* flex-wrap: nowrap;
      flex-direction: row
      flex-wrap: nowrap
      white-space: nowrap
      color: #222222

    .item-content
      min-height: 40px
      width: 70%
      float: right
      display: flex
      flex-direction: row
      flex-wrap: nowrap
      align-items: center
      text-align: center
      position: relative
      margin: 0 !important
      .email-trigger
        top: 7px
        position: absolute
        right: 0 !important
      .password-field-container
        position: relative
        width: 100%
      .password-change-wrapper
        display: flex
        width: 100%
        flex-direction: column
        .password
          margin-bottom: 24px
      .password-change-suggestion-container
        position: relative !important
      .success-message
        display: flex
        flex-direction: revert
        position: absolute
        right: 0px
        color: #27C245 !important
      .success-message-consent
        display: flex
        flex-direction: revert
        position: absolute
        right: 0px
        color: #27C245 !important
        margin-top: 12px
        margin-right: 0 !important
      .PasswordStrengthBar
        top: 38px !important
      input, .selection, .input
        // width: 200px !important
        width: 100% !important
        border: none !important
        background: #F5F5F5 !important
        border-radius: 6px
        border: none
        float: left
      .user-email
        display: flex
        text-align: center
        align-items: center
        height: 40px
        margin-left: 8px
        font-family: Roboto
        font-style: normal
        // font-weight: 500
        font-size: 14px
        line-height: 16px
        // margin-top: 4px
      .pricing-plan-container
        margin-left: 8px    
        align-items: center
        justify-content: space-between
        flex-direction: row
        display: flex
        width: 100%
        span
          font-weight: 400 !important
        .text-bold
          font-weight: 900 !important
        .big-button
          margin: 0
        div
          & :first-child
            margin-right: 10px
          & :nth-child(2)
            color: #666 !important
      &.email-container
        flex-direction: column
        text-align: start
        align-items: start
        .success-message
          position: unset !important
          right: unset !important
          background-color: #E9F9EC
          padding: 8px
          border-radius: 6px
          display: flex
          flex-direction: row
          justify-content: space-between
          margin: 8px 0 16px 0
          .message-sent-success
            width: 24px 
            height: 24px
            padding: 12px
            margin-right: 8px

        .email-change-wrapper
          display: flex
          flex-direction: column
          .info-container,.message-sent-success
            width: 24px 
            height: 24px
            padding: 12px
            margin-right: 8px
            // padding-left: 32px
            // padding-top: 32px
          .info-message
            width: 100%
            background-color: #F5F3F8
            padding: 8px
            border-radius: 6px
            display: flex
            flex-direction: row
            justify-content: space-between
            margin: 8px 0 16px 0
          .email
            position: relative
    // my own styles - check with Jecka
    .item-content input, .item-content .selection
      border: 1px solid #503483 !important
      color: #503483 !important
      background: #fff !important
      border-radius: 6px !important
      &:hover
        border: 1px solid #333333 !important
        color: #333333 !important
      &:active, &:focus
        border: 1px solid #000000 !important
        color: #000000 !important
      &.disabled
        border: 1px solid #503483 !important
        color: #503483 !important
      &.error
        border: 1px solid #E01E5A !important
    .item-content .error input
      border: 1px solid #E01E5A !important
      &:focus
        border: 1px solid #E01E5A !important
        // color: #000000 !important
    .item-content .success input
      border: 1px solid #27C245 !important
      &:focus
        border: 1px solid #27C245 !important
        // color: #000000 !important

  hr
    margin: 0 !important//margin-bottom: 16px
    border: none
    // color: #D8D8D8 //4F4F4F
    background-color: #D8D8D8
    height: 1px !important
    line-height: 1px
    width: calc(100%)

  .profile-wrapper .item-content a
    margin-left: 8px
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    white-space: nowrap
    border: none !important
    &:hover,&:active,&:focus
      border: none !important
      

  .pwrd-icon, .pwrd-icon-hidden
    position: absolute
    top: 7px
    right: 7px

  .button
    background: #503483
    color: #f2f2f2
    &:hover
      background: #000
    & .disabled
      background: #3D1D75

  .service-items-container
    width: 100%
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: start
    gap: 9px
    align-items: center
    text-align: center 
    margin: 0 auto

  .service-item
    min-width: 76px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
    margin: 14px 0
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 12px
    color: #3D1D75
    cursor: pointer
    pointer-events: all
    div
      opacity: 0.3
      margin-bottom: 4px
      pointer-events: none
  .service-item
    &.active
      color: #000
      div
        opacity: 1 !important

    .slask,.google-chat,.telegram,.microsoft-teams,.zoom,.skype,.mattermost,.google-meet,.whatsapp,.signal,.google-workspace,.office-365,.own-server,.other-service
      background-size: cover
      -webkit-background-size: cover
      -moz-background-size: cover
      -o-background-size: cover
    .slask
      background-image: $slackBtn
      width: 24px
      height: 24px
    .google-chat
      background-image: $googleChatBtn
      width: 24px
      height: 24px
    .telegram
      background-image: $telegramBtn
      width: 24px
      height: 24px
    .microsoft-teams
      background-image: $microsoftTeamsBtn
      width: 24px
      height: 24px
    .zoom
      background-image: $zoomBtn
      width: 24px
      height: 24px
    .skype
      background-image: $skypeBtn
      width: 24px
      height: 24px
    .mattermost
      background-image: $mattermostBtn
      width: 24px
      height: 24px
    .google-meet
      background-image: $googleMeetBtn
      width: 24px
      height: 24px
    .whatsapp
      background-image: $whatsAppBtn
      width: 28.73px
      height: 24px
    .signal
      background-image: $signalBtn
      width: 22.92px
      height: 24px
    .google-workspace
      background-image: $googleWorkspaceBtn
      width: 99px
      height: 13.5px
      margin: 8px 0
    .office-365
      background-image: $office365Btn
      width: 20.25px
      height: 24px
    .own-server
      background-image: $serverBtn
      width: 24px
      height: 24px
    .other-service
      background-image: $otherBtn
      width: 24px
      height: 24px  
  

.dark
  .Profile
    background-color: #131313
    .item-content
      &.email-container
        .success-message
          background-color: #233226
      .info-container
        width: 24px 
      .info-message
        background-color: #323232 !important
    .service-item
      color: #686868
      &.active
        color: #f2f2f2
    .email-error
      color: #E01E5A !important
    .item-content .error input
      border: 1px solid #E01E5A !important
      &:focus
        border: 1px solid #E01E5A !important
    .item-content .success input
      border: 1px solid #27C245 !important
      &:focus
        border: 1px solid #27C245 !important
        // color: #000000 !important
    .ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before, .ui.toggle.checkbox input:checked~label:before
      background-color: #BDBDBD !important
    .ui.toggle.checkbox label
      &:hover
        &::before
          background: #BDBDBD !important
      &::before
        border: none !important
        width: 38px
        height: 18px
        background: #686868 !important
      &::after
        width: 15px
        height: 15px
        top: 1.5px
        left: 1.5px
        box-shadow: none
        background: #333333 !important
    .ui.toggle.checkbox input:checked~label:after
      left: 21.5px
      box-shadow: none

    .item-content .selection
      & .disabled
        // border: 1px solid #686868 !important
        color: #686868 !important

    .profile-wrapper,input, .input,.item-content input, .item-content .selection,.profile-label
      background: #222222 !important
      color: #bdbdbd !important
    hr
      background-color: #4F4F4F !important
    .ui.selection.active.dropdown .menu
      .item
        background-color: #222
        &:hover
          background-color: #333 !important
          span
            color: #F2F2F2 !important
        &.disabled
          color: #686868
        &.active
          background-color: #222 !important
          span
            color: #F2F2F2 !important
    .item-content input, .item-content .selection
      border: 1px solid #BDBDBD !important
      &:hover, &:active, &:focus
        border: 1px solid #f2f2f2 !important
        color: #f2f2f2 !important
      & .disabled
        border: 1px solid #686868 !important
        color: #686868 !important

    .item-label,h5
      color: #bdbdbd !important

    .button
      background: #BDBDBD !important
      color: #333333 !important
      &:hover
        background: #f2f2f2 !important
      & .disabled
        background: #686868 !important

@media screen and (max-width: 900px)
  .Profile

    .profile-wrapper
      // margin-top: 24px
      // padding: 16px
      width: 640px
      margin-bottom: 16px

    .profile-content
      margin-bottom: 16px
      width: 100%

    .content-box
      margin-top: 80px
      margin-bottom: 80px
      width: calc(100% - 20px) !important

    .item-label
      width: 25% !important

    .item-content
      width: 75% !important

    .profile-wrapper .item-content a
      margin-left: 8px
    .pass-change
      display: flex
      flex-wrap: wrap !important
      white-space: break-spaces !important

    .checkbox
      margin-left: 10px !important

@media screen and (max-width: 500px)
  .Profile
    .profile-buttons-container
      .success-message
        position: absolute
        right:  unset
        left: -60px
        top: -328px//unset
        // bottom: -30px
        // height: 40px
        // width: 80px

.App ::-webkit-scrollbar, .ui .modal ::-webkit-scrollbar
  width: 2px !important
.App ::-webkit-scrollbar-track, .ui .modal ::-webkit-scrollbar-track
  background: unset !important
.App ::-webkit-scrollbar-thumb, .ui .modal ::-webkit-scrollbar-thumb
  cursor: pointer
  background: #503483 !important
  // visibility: hidden
  // div
  //   &:hover div::-webkit-scrollbar-thumb
  //     visibility: visible
  // &:hover
  //   visibility: visible
    // border-radius: 5px
    // /* background: rgba(0,0,0,.25); */
    // -webkit-transition: color .2s ease
    // transition: color .2s ease

// .bottom-borderless:hover>div::-webkit-scrollbar-thumb
//   visibility: visible
// .user-data-table:hover>div::-webkit-scrollbar-thumb
//   visibility: visible
// .user-data-table:hover>div>div::-webkit-scrollbar-thumb
//   visibility: visible

// .App
//   &::-webkit-scrollbar 
//     background-color: transparent
// .App:hover
//   &::-webkit-scrollbar-thumb
//     background-color: transparent
body
.App *
  scrollbar: #503483
  scrollbar-width: thin

body:hover
  &::-webkit-scrollbar-thumb
    background-color: black
.ui .modal
  &.dark ::-webkit-scrollbar-thumb
    background: #BDBDBD !important
    visibility: hidden
    &:hover
      visibility: visible
// .cookie-consent-container
//   background-color: #fff
//   display: flex
//   left: 0px
//   position: fixed
//   width: 100%
//   z-index: 999
//   bottom: 0px
//   &.dark
//     background-color: #222222
.CookieConsent
  justify-content: center !important
  .cookie-consent-text
    flex: unset !important

.cookie-borderless-button
  font-style: normal !important
  font-weight: normal !important
  font-family: 'Roboto' !important
  font-size: 14px
  line-height: 24px
  color: #3D1D75
  background: unset !important
  border: none !important
  &:hover
    color: #000
  &.dark
    color: #BDBDBD
    &:hover
      color: #F2F2F2

.cookie-modal-button
  border-radius: 6px !important
  font-style: normal !important
  font-weight: normal !important
  font-family: 'Roboto' !important
  font-size: 14px !important
  line-height: 24px
  background: #503483 !important
  border-radius: 6px
  margin: 24px auto
  color: #fff !important
  &:hover
    background: #000 !important
    color: #fff !important
  &.disabled
    background: #A395BD !important
  &.success
    background: #000 !important
    opacity: 1 !important
    &.disabled
      opacity: 1 !important
  &.dark
    background: #BDBDBD !important
    color: #000 !important
    &:hover
      background: #f2f2f2 !important
    &.disabled
      background: #686868 !important
    &.success
      background: #F2F2F2 !important

.App
  background-color: #f2f2f2
  line-height: 24px !important
  .theme-toggler
    color: #3D1D75 !important
    cursor: pointer !important
    label
      pointer-events: none !important
  .select-dropdown
    border: 1px solid
    border-color: #3D1D75
    display: flex !important
    align-items: center
    text-align: center
    justify-content: space-between
    padding: 0 8px
    // width: 32px
    height: 32px
    border-radius: 6px
    width: calc( 100% - 16px ) !important
    margin: 8px auto 0 auto
    .text
      font-family: Roboto
      font-style: normal
      font-weight: normal !important
      font-size: 14px
      line-height: 24px
    .menu
      width: 100%
      border: none !important
      .item
        border: none !important  
        .text
          color: #3D1D75
          font-weight: 400
          &:hover
            background-color: #F7F7F7
            color: #333333
            border-radius: 6px !important
          &.disabled
            color: #BDBDBD
        &.active
          background-color: #fff// !important // HERE IS
          // font-weight: normal !important
          .text
            color: #000 !important
          &:hover
            background-color: #F7F7F7 !important
            color: #333333
            border-radius: 6px !important
    .divider.text
      display: flex
      align-items: center
      text-align: center
      flex-direction: row
      img
        width: 24px
        height: 24px
  .mail-to-container
    display: flex
    flex-direction: row
    align-items: center
    margin: 24px 0 10px 0
    cursor: pointer
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 24px
  .additional-container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
  .additional-login-options
    display: flex
    flex-direction: row
    justify-content: space-between
    line-height: 24px
    align-items: center !important
    margin: 0 0 36px 0 !important
    font-family: Roboto
    font-style: normal
    font-weight: normal
    position: relative
    .borderless-button
      font-style: normal !important
      font-weight: normal !important
      font-family: 'Roboto' !important
      font-size: 14px !important
      line-height: 24px
    .checkbox-custom
      margin: 0 !important
      float: left
      line-height: 24px
      font-size: 14px !important
      font-weight: normal
      label
        margin: 0 !important
        font-style: normal !important
        font-weight: normal !important
        font-size: 14px !important
        line-height: 24px
        &::before
          top: 3px
  .invitationForm
    position: relative
    width: 100%
    text-align: start !important
    .ui.input,.item-content
      width: 100%
      text-align: start !important
      input
        text-align: start !important
    .item-content
      margin-bottom: 8px !important
    .theme-toggler
      color: #3D1D75 !important
      cursor: pointer !important
      label
        pointer-events: none
    .user-avatar
      width: 24px !important
      height: 24px !important
      border-radius: 50% !important
    h5
      font-weight: 500
      font-size: 14px !important
      line-height: 24px
    .big-button
      width: 100%

    .select-all
      // position: absolute
      // top: 0
      // right: 0
      margin-bottom: 8px
      border: none
      background: unset
      color: #3D1D75
      &:hover
        color: #000
    .slackInvitationsContainer
      // border: 1px solid #3D1D75
      box-sizing: border-box
      border-radius: 6px
      margin-bottom: 24px
      // min-height: 164px
      max-height: 164px
      min-width: 352px
      overflow-y: auto
      overflow-x: hidden
      display: flex
      flex-direction: column
      &.do-not-connected
        overflow-y: hidden
        min-height: unset !important
      .no-data-center
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        text-align: center
        height: 164px !important
        &.do-not-connected
          overflow-y: hidden
          height: unset !important
        p
          color: #666666

      .user-holder
        display: flex
        text-align: center
        flex-direction: row
        padding: 9px 7px 6px 11px !important
        border: 1px solid #d8d8d8 //#4F4F4F
        border-left: none !important
        border-right: none !important
        cursor: pointer
        position: relative
        // p:first-of-type
        //   margin-right: 20px
        .date-container
          position: absolute
          right: 8px
        img
          margin-right: 5px
        &:hover
          background: #ECE8F1
        &.selected
          background: #ECE8F1
          border-color: #ECE8F1
        &.granted
          border: none !important
          &:hover
            background: unset
        &.invited
          background: #F5F3F8
          border-color: #F5F3F8
          color: #666666
          pointer-events: none
          cursor: default
          p
            margin: 0
            line-height: 24px
    textarea
      width: 100%

  .ui.checkbox .box:before, .ui.checkbox label:before
    background: unset !important
    // border-radius: 2px
    border: 1px solid #3D1D75
  .ui.checkbox .box:hover, .ui.checkbox label:hover, .ui .checked .checkbox input:focus
    &:before
      // border-radius: 2px
      border: 1px solid #000
  .ui.checkbox input:checked:focus~label:before
    border-color: #000

  .ui.menu
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1) !important
    &:after
      display: none !important
    #navigateToConnectslackID // .menu-itm ? 
      span, .connect-container // div ? 
        pointer-events: none
  
    .item 
      .item.disabled
        // padding: 0 !important
  .big-button
    background: #503483 !important
    border-radius: 6px
    margin: 24px auto
    color: #fff !important
    &:hover
      background: #000 !important
      color: #fff !important
    &.disabled
      background: #A395BD !important
    &.success
      background: #000 !important
      opacity: 1 !important
      &.disabled
        opacity: 1 !important
    img
      margin-right: 10px
    span
      color: #fff
  .borderless-button
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 24px
    color: #3D1D75
    background: unset !important
    border: none !important
    &:hover
      color: #000
    &.error
      color: #E01E5A !important
      background-color: unset !important


  .ui.fitted.toggle.checkbox
    width: 40px//38px
    min-height: 16px !important
  .ui.toggle.checkbox
    min-height: unset !important
  .ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before, .ui.toggle.checkbox input:checked~label:before
    background-color: #503483 !important
  .ui.toggle.checkbox label
    &:hover
      &::before
        background: #3D1D75
    &::before
      width: 40px//38px
      height: 20px
      background: #BDBDBD

    &::after
      width: 16px
      height: 16px
      top: 2px
      left: 2px
      box-shadow: none

  .ui.toggle.checkbox input:checked~label:after
    left: 22px
    box-shadow: none
    background: #fff

  #tutorial
    height: calc( 100% - 48px )
    position: fixed//absolute
    overflow-y: auto
    // overflow-x: auto
    top: 48px
    right: 0px
    background: #fff
    z-index: 11
    opacity: 0
    width: 0
    font-size: 14px
    line-height: 24px
    transition: 0.5s
    // visibility: hidden
    .tutor-content
      display: none
    #tutorialHome
      color: #3D1D75
      &:hover
        color: #000
      &.active
        color: #000
    &.right-sidebar
      width: 428px
      opacity: 1
      .tutor-content
        display: block !important
      // // visibility: visible !important
    .copy-input, .big-button
      width: 352px
      height: 40px
      cursor: pointer
    .chain-container .menu-itm
      cursor: pointer
    .tutorial-header
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      padding: 24px
      border-top: 1px solid #D8D8D8
      border-bottom: 1px solid #D8D8D8
      .tutorial-title-container
        font-size: 16px
        line-height: 24px
        display: flex
        flex-direction: row
        #tutorialHome
          cursor: pointer
      .arrow-right
        background-image: url('./img/arrow.svg')
        transform: rotate(270deg)
        width: 24px
        height: 24px
        background-size: cover
        -webkit-background-size: cover
        -moz-background-size: cover
        -o-background-size: cover
        margin: 0 15px
        padding: none
    #tutorialContainer
      .item-container
        font-size: 16px
        line-height: 24px
        display: flex
        color: #3D1D75
        padding: 24px
        border-bottom: 1px solid #D8D8D8
        cursor: pointer
        &:hover
          background: #f2f2f2
        div:first-child
          margin-right: 12px !important
        div,.tutorial-header div
          pointer-events: none
    p
      margin-bottom: 8px !important
      line-height: 24px
    #graphContainer
      font-size: 14px
      // justify-content: center !important
      display: flex
      flex-direction: column
      align-items: flex-start
      // text-align: start
      // .avatar-default
      //   margin: 0 auto
    #graphContainer,#gettingStartedContainer,#supportContainer, #settingsContainer, #profileContainer, #switchersContainer, #detailsContainer, #exportContainer
      margin: 24px
    #supportContainer .email-error
      top: 161px !important

    #supportContainer textarea,.custom-textarea
      border-radius: 6px !important
      width: 376px !important
      min-height: 160px !important
      overflow-y: auto !important
      display: flex
      flex-wrap: wrap
      &.error
        border-color: #E01E5A !important
    #supportContainer button
      width: 378px !important
      border-radius: 6px !important
    #supportContainer .thanks-issue-message
      font-family: Roboto
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 24px
      text-align: center
      display: flex
      justify-content: center
      margin: 76px auto
    .avatarFeatures
      // margin: 24px 24px 0 24px
      width: 100%
      justify-content: space-evenly !important
      display: flex
      flex-direction: raw
      align-items: center
      .avatar-with-label
        div
          margin-bottom: 24px
      div
        // margin-bottom: 24px
        display: flex
        justify-content: center
        align-items: center
        text-align: center
        flex-direction: column
    .avatartRelations
      position: relative
      width: 280px
      justify-content: space-between// center !important
      display: flex
      flex-direction: raw
      align-items: center
      margin: 0 auto 24px auto
      hr
        position: absolute
        top: 36px
        left: 72px
        width: 121px
        border-color: transparent !important
        border-bottom: 2px #3D1D75 solid !important
      &.one-side-connection
        position: relative
        .arrow-right
            position: absolute
            right: 84px
            top: 37px
            z-index: 3
            width: 0
            height: 0
            border-left: 8px solid #3D1D75
            border-top: 8px solid transparent
            border-bottom: 8px solid transparent
        hr
          border-bottom: 2px #3D1D75 dashed !important
          
    #settingsContainer
      .ui.button
        width: 156px !important
        padding: 0 !important

    .avatar-default
      background-image: url('./img/avatar-default.svg')
      width: 69px
      height: 96px
      background-size: cover
      -webkit-background-size: cover
      -moz-background-size: cover
      -o-background-size: cover
      margin: 0 15px
      padding: none
      pointer-events: none
      &.center
        margin: 0 auto 24px
        // margin-top: 24px
    .avatar-default2
      background-image: url('./img/avatar-default2.svg')
      width: 69px
      height: 96px
      background-size: cover
      -webkit-background-size: cover
      -moz-background-size: cover
      -o-background-size: cover
      margin: 0 15px
      padding: none
      pointer-events: none
    .avatar-default-features
      background-image: url('./img/avatar-default-features.svg')
      width: 69px
      height: 96px
      background-size: cover
      -webkit-background-size: cover
      -moz-background-size: cover
      -o-background-size: cover
      margin: 0 15px
      padding: none
      pointer-events: none
    .avatar-default-features2
      background-image: url('./img/avatar-default-features2.svg')
      width: 69px
      height: 96px
      background-size: cover
      -webkit-background-size: cover
      -moz-background-size: cover
      -o-background-size: cover
      margin: 0 15px
      padding: none
      pointer-events: none
    .menu-itm
      margin: 24px auto !important

    .big-button
      width: 352px
      height: 40px
      background: #503483 !important
      border-radius: 6px
      display: flex !important
      justify-content: center
      align-items: center
      text-align: center
      margin: 0 auto 24px auto
      &:hover
        background: #000 !important
        color: #fff
      & .disabled
        background: #A395BD !important
      img
        margin-right: 10px
      span
        color: #fff
      &.error
        background: #E01E5A !important
        color: #fff !important
        &:hover
          background: #C71A51 !important
        &.disabled
          background: #EB8AA8 !important
    .slack-btn
      background: #fff !important
      border: #503483 2px solid !important
      span
        color: #503483
        font-family: 'Roboto' !important
      &:hover
        background: #000 !important
        span
          color: #fff
    .input-container
      display: flex
      justify-content: center
      margin: 0 auto 24px auto !important
    .button
      background: #3D1D75 !important
      color: #fff !important
      font-size: 14px !important
      height: 40px im !important
      padding: 0.93em 1.5em 0.93em !important
      &:hover
        background: #333333 !important
    .copy-input
      border: 2px solid #3D1D75
      border-radius: 6px

    // .copy-button
    //   position: absolute
    //   right: 110px
    //   height: 40px
    //   background: #503483 !important
    //   color: #fff !important
    //   border-radius: 6px
    //   &:hover
    //     background: #000 !important
    //   & .disabled
    //     background: #A395BD !important

    .input-image-description
      text-align: center !important
      margin-top: 8px
      font-size: 12px

  .middle-itm
    margin-left: 105px !important
  .navigation-bar
    position: fixed
    display: flex
    justify-content: space-between
    width: 100%
    flex-direction: row
    border: none !important
    height: 48px
    margin-bottom: 24px !important
    // display: visible !important
    z-index: 10 !important
  .invisible
    display: none !important

  .page-content
    padding-top: 66px !important
    background-color: #F2F2F2
    min-height: 100vh// calc( 100vh - 72px) // 48height + 24px margin bottom = 72px
    // padding-bottom: 16px
    &.unautentificated
      height: 100vh
      padding-top: 0 !important

  .menu
    // background: #F2F2F2 !important
    // color: #3D1D75
  &:focus, &:active,&:hover,&:checked
    color: #000000 !important
      // font-weight: 700 !important
  .menu-itm
    color: #3D1D75 //!important
    border: none !important
    background-color: transparent !important
    &:focus, &:active,&:hover,&:checked
      color: #000000 !important
      // font-weight: 700 !important
  .active .menu-itm,.ui.menu .active.item, .menu-itm:hover
    color: #000000 !important
    background: transparent !important
    // font-weight: bold

  .menu-itm
    display: flex
    text-align: center
    justify-content: center
    align-items: center
    font-family: 'Roboto'
    font-style: normal
    // font-weight: normal
    font-size: 16px
    line-height: 24px
    color: #3D1D75
    span
      pointer-events: none !important
    div
      pointer-events: none !important
  .copy-button-container
    width: 100%
    min-width: 352px
    position: relative
    .ui.button
      margin: 0

.App
  &.dark
    background-color: #131313 !important
    .select-dropdown
      border-color: #BDBDBD
      color: #F2F2F2
      .menu
        .item
          background-color: #222222
          .text
            color: #BDBDBD
            &.disabled
              color: #686868
            &:hover
              background-color: #333333
          &:hover
            background-color: #333333 !important
            color: #F2F2F2
          &.active, &.selected
            &:hover
              background-color: #333333 !important
            .text
              color: #F2F2F2 !important
            &:hover
              color: #f2f2f2 !important 
    .main-registration-form
      p
        color: #BDBDBD !important
    .mail-to-container
      p
        color: #BDBDBD !important
    .ui.toggle.checkbox.theme-toggler
      label
        &::before
          background: #bdbdbd !important
          // color: #BDBDBD !important
    .invitationForm
      .select-all
        color: #bdbdbd
        &:hover
          color: #f2f2f2
      .slackInvitationsContainer
        // border: 1px solid #BDBDBD
        .user-holder
          border: 1px solid #4F4F4F
          &:hover
            background: #323232 !important
          &.selected
            background: #323232 !important
            border-color: #323232 !important
            color: #f2f2f2 !important
          &.granted
            border: none !important
            &:hover
              background: unset !important
          &.invited
            background: #2A2A2A !important
            border-color: #2A2A2A
            color: #666666 !important
    .ui.checkbox .box:before, .ui.checkbox label:before
      background: unset !important
      border: 1px solid #BDBDBD
    .ui.checkbox .box:hover, .ui.checkbox label:hover, .ui .checked .checkbox input:focus
      &:before
        border: 1px solid #f2f2f2 !important
    .ui.checkbox input:checked:focus~label:before
      border-color: #f2f2f2 !important
    // .ui.checkbox input:checked~label:after
    //   // color: #fff
    //   background: #222

    .page-content
      background-color: #131313
    .menu
      background: #222222 !important
      color: #BDBDBD
      &:focus, &:active,&:hover,&:checked
        color: #F2F2F2 !important
      .menu-itm
        color: #BDBDBD
        &:focus, &:active,&:hover,&:checked
          color: #F2F2F2 !important
    .borderless-button
      color: #BDBDBD
      &.error
        background: unset !important
        color: #E01E5A !important
        &:hover
          background: unset !important  
          color: #E01E5A !important
      &:hover
        color: #F2F2F2
    .ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before, .ui.toggle.checkbox input:checked~label:before
      background-color: #474747 !important
    .ui.toggle.checkbox label
      &:hover
        &::before
          background: #BDBDBD !important
      &::before
        border: none !important
        width: 40px//38px
        height: 20px
        background: #686868 !important
      &::after
        width: 16px
        height: 16px
        top: 2px
        left: 2px
        box-shadow: none
        background: #333333 !important
    .ui.toggle.checkbox input:checked~label:after
      left: 22px
      box-shadow: none

    .active .menu-itm,.ui.menu .active.item, .menu-itm:hover
      color: #F2F2F2 !important
      background: transparent !important
    ::-webkit-scrollbar, .ui .modal .dark ::-webkit-scrollbar
      width: 2px !important
    ::-webkit-scrollbar-track, .ui .modal .dark ::-webkit-scrollbar-track
      background: unset !important
    ::-webkit-scrollbar-thumb, .ui .modal .dark ::-webkit-scrollbar-thumb
      cursor: pointer
      background: #BDBDBD !important
        // border-radius: 5px
        // /* background: rgba(0,0,0,.25); */
        // -webkit-transition: color .2s ease
        // transition: color .2s ease
    .big-button
      background: #BDBDBD !important
      &:hover
        background: #f2f2f2 !important
      &.disabled
        background: #686868 !important
      &.success
        background: #F2F2F2 !important

    #tutorial
      background: #222222
      color: #bdbdbd
      .menu-itm
        color: #bdbdbd
      #tutorialHome
        color: #BDBDBD
        &:hover
          color: #fff
        &.active
          color: #fff
      .tutorial-header
        border-top: 1px solid #333333
        border-bottom: 1px solid #333333
        .active
          color: #fff
        .arrow-right
          background-image: url('./img/arrow-dark.svg')
      #tutorialContainer
        .item-container
          color: #BDBDBD
          border-bottom: 1px solid#333333
          &:hover
            background: #333333
            div
              color: #FFFFFF
      .avatartRelations
        hr
          fill: none
          color: none !important
          border-color: transparent !important
          border-bottom: 2px #f2f2f2 solid !important
        &.one-side-connection
          .arrow-right
              border-left: 8px solid #f2f2f2
          hr
            border-bottom: 2px #f2f2f2 dashed !important
      ////////////////
      .big-button
        &.error
          background: #E01E5A !important
          // color: #bdbdbd
          &:hover
            background: #C71A51 !important
          &.disabled
            background: #7A1836 !important
        &.success
          background: #27C245 !important

      .slack-btn
        border: 2px solid #BDBDBD !important
        background: #222222 !important
        &:hover
          border-color: #f2f2f2 !important
          & span
            color: #333333 !important
        span
          color: #BDBDBD !important
      .copy-input
        border: 2px solid #BDBDBD
        border-radius: 6px
      .ui.button
        background: #BDBDBD !important
        color: #333 !important
        &:hover
          background: #f2f2f2 !important
      //////////////

    .avatar-default
      background-image: url('./img/avatar-default-dark.svg') !important
    .avatar-default2
      background-image: url('./img/avatar-default-dark2.svg') !important
    .avatar-default-features
      background-image: url('./img/avatar-default-features-dark.svg') !important
    .avatar-default-features2
      background-image: url('./img/avatar-default-features-dark2.svg') !important

.demoModal
  position: absolute
  top: 64px
  left: 16px
  border: none !important
  border-radius: 8px !important
  height: 296px !important
  width: 320px !important
  a
    font-size: 14px
    line-height: 24px
  .header
    border-bottom: none !important
    font-size: 16px !important
    border-radius: 6px 6px 0 0 !important
    padding: 24px 24px 20px 24px !important
  .content
    font-size: 14px !important
    padding: 0 24px !important
  .actions
    border-top: none !important
    border-radius: 0 0 6px 6px !important
    padding: 24px !important
    &.center
      display: flex
      justify-content: center
  .close-container
    position: absolute
    top: 24px !important
    right: 24px !important
    // color: #3D1D75 !important
    // &:hover
    //     color: #333333 !important

  // .button
  //     background: #3D1D75 !important
  //     color: #fff !important
  //     font-size: 14px !important
  //     height: 40px im !important
  //     padding: 0.93em 1.5em 0.93em !important
  //     &.wide
  //         width: 208px
  //         margin-left: 0 !important
  //         margin: 0 auto !important
  //     &:hover
  //         background: #333333 !important
  .description
    display: flex
    .list-item
      display: flex
      flex-direction: row
      margin: 8px 0
      text-align: center !important
      line-height: 24px
      vertical-align: middle !important
      p
        line-height: 24px
      .list-unit
        margin-right: 8px
        display: block
        background: #000 !important
        color: #fff
        text-align: center
        vertical-align: middle
        line-height: 24px
        border-radius: 50%
        width: 24px
        height: 24px

  &.dark
    background: #222222 !important
    .list-item
      .list-unit
        background: #f4f4f4 !important
        color: #000 !important
      .close
        color: #BDBDBD !important
      div
        background: #222222 !important
        color: #bdbdbd !important
      .button
        background: #BDBDBD !important
        color: #333333 !important
        &:hover
          background: #f2f2f2 !important
          & .disabled
            background: #686868 !important

@media screen and (max-width: 900px)
  .App
    .ui.menu .item
      padding: 4.5px 10px !important

    .middle-itm
      margin-left: 0 !important
    #tutorial
      &.right-sidebar
        width: 100% !important
        z-index: 25 !important
      #supportContainer
        .ui.button
          width: 375px !important
      #profileContainer
        .input-example-container, .input2-example-container
          margin: 0 auto

.connectslack-item span
  display: none !important
.is-mobile
  display: none
.is-mobile-active
  &::after
    content: ''
    position: fixed
    top: 48px
    bottom: 0
    left: 0
    right: 0
    background-color: rgba(0,0,0,0.5)
    z-index: 998
@media screen and (max-width: 767px)
  .App
    .navigation-bar
      padding: 0 8px
      .connectslack-menu
        display: none !important
      // .menu
      //   &:nth-of-type(2)
      //     position: absolute
      //     // right: 228px
      //     left: 100px
      //     top: 6.5px          
    #tutorial
      #supportContainer
        .ui.button
          width: 327px !important

  .CookieConsent
    .cookie-consent-text
      flex: 1 0 300px !important
  .App
    .ui.menu .item
      padding: 4.5px 0 !important
  .is-mobile
    display: block
    background-image: url('./img/other.svg')
    background-size: cover
    width: 24px
    height: 24px
    pointer-events: none
    margin: 12px 0
    pointer-events: all
    z-index: 2
  .forcepage-menu
    position: absolute
    right: 40px
    top: 6.5px  
  .is-mobile-active
    background-image: url('./img/other-active.svg')
  .dark
    .is-mobile
      background-image: url('./img/other-dark.svg')
    .is-mobile-active
      background-image: url('./img/other-active-dark.svg')
  // .grouped-items
  //   position: relative
  .grouped-items>.item
    box-shadow: 0px 0px 24px 0px #0000001a !important
    border-radius: 6px
    position: absolute !important
    top: calc(100% + 3px) !important
    right: 3px !important
    flex-direction: column !important
    background-color: inherit !important
    width: 180px !important
    align-items: flex-start !important
    color: inherit !important
    z-index: 999
    &.item>.item
      margin: 4px 0 !important
      // margin-right: 50px !important
      position: relative !important
      color: inherit !important
    .tutorial-item
      color: inherit !important
      &>div
        justify-content: flex-start !important
        margin-right: 130px !important
        color: inherit !important
        // pointer-events: all !important
        &:after
          content: 'Tutorial'
          position: absolute !important
          padding: 4.5px 8px !important
          left: 30px
          top: 0
          bottom: 0
          line-height: 24px
          font-size: 16px
          color: inherit !important
    .settings-item
      .menu-itm
        justify-content: flex-start !important
        width: 130px !important
        &::after
          content: 'Settings'
          position: absolute !important
          padding: 4.5px 8px !important
          left: 30px
          top: 0
          bottom: 0
          line-height: 24px
          color: inherit !important
    .connectslack-item
      display: flex !important
      .menu-itm
        justify-content: flex-start !important
        width: 130px !important
        &::after
          content: 'Connection'
          position: absolute !important
          padding: 4.5px 8px !important
          left: 30px
          top: 0
          bottom: 0
          line-height: 24px
          color: inherit !important
    .profile-item
      .menu-itm
        justify-content: flex-start !important
        width: 130px !important
        &::after
          content: 'Profile'
          position: absolute !important
          padding: 4.5px 8px !important
          left: 30px
          top: 0
          bottom: 0
          line-height: 24px
          color: inherit !important
    .exit-item
      .menu-itm
        justify-content: flex-start !important
        width: 130px !important
        &::after
          content: 'Exit'
          position: absolute !important
          padding: 4.5px 8px !important
          left: 30px
          top: 0
          bottom: 0
          line-height: 24px
          color: inherit !important


@media screen and (max-height: 700px)
  .App
    .page-content
      background-color: #F2F2F2
      // height: 100%//calc(100% - 72px) // 48height + 24px margin bottom = 72px
      &.unautentificated
        height: 100%
// 961px
@media screen and (min-width: 768px)
  .interactive-scroll
    overflow-y: scroll
    mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 3px, black 3px)
    mask-size: 100% 10000px
    mask-position: left bottom
    -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 3px, black 3px)
    -webkit-mask-size: 100% 10000px
    -webkit-mask-position: left bottom
    transition: mask-position 0.3s, -webkit-mask-position 0.3s
    &:hover
      -webkit-mask-position: left top
      mask-position: left top
  @-moz-document url-prefix()
    body
      overflow-y: hidden
    .interactive-scroll
      mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 8px, black 8px)
      -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 8px, black 8px)

@media screen and (min-width: 961px)
  .interactive-scroll
    overflow-y: scroll
    mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 3px, black 3px)
    mask-size: 100% 10000px
    mask-position: left bottom
    -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 3px, black 3px)
    -webkit-mask-size: 100% 10000px
    -webkit-mask-position: left bottom
    transition: mask-position 0.3s, -webkit-mask-position 0.3s
    &:hover
      -webkit-mask-position: left top
      mask-position: left top
  @-moz-document url-prefix()
    body
      overflow-y: hidden
    .interactive-scroll
      mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 8px, black 8px)
      -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 8px, black 8px)
      
@media screen and (min-width: 1024px)
  .interactive-scroll
    overflow-y: scroll
    mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 3px, black 3px)
    mask-size: 100% 10000px
    mask-position: left bottom
    -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 3px, black 3px)
    -webkit-mask-size: 100% 10000px
    -webkit-mask-position: left bottom
    transition: mask-position 0.3s, -webkit-mask-position 0.3s
    &:hover
      -webkit-mask-position: left top
      mask-position: left top
  @-moz-document url-prefix()
    body
      overflow-y: hidden
    .interactive-scroll
      mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 8px, black 8px)
      -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 8px, black 8px)

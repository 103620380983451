.export-wrapper
    
    
    .ui.selection.dropdown:focus
        border-color: unset !important
    .custom-select
        border: 1px solid #503483 !important
        color: #503483 !important
        background: #fff !important
        border-radius: 6px !important
        &:hover
            border: 1px solid #333333 !important
            color: #333333 !important
        &:active, &:focus
            border: 1px solid #000000 !important
            color: #000000 !important
        &.disabled
            border: 1px solid #503483 !important
            color: #503483 !important

    .checkbox-custom
        margin: 0 !important
        float: left
        line-height: 24px
        font-size: 14px !important
        font-weight: normal
        label
            margin: 0 !important
            font-style: normal !important
            font-weight: normal !important
            font-size: 14px !important
            line-height: 24px
            &::before
                top: 3px

          
    .ui.checkbox .box:before, .ui.checkbox label:before
        background: unset !important
        // border-radius: 2px
        border-color: #3D1D75
        border: 1px solid #3D1D75
    .ui.checkbox .box:hover, .ui.checkbox label:hover, .ui .checked .checkbox input:focus
        &:before
            // border-radius: 2px
            border: 1px solid #000
            border-color: #000
    .ui.checkbox input:checked:focus~label:before
        border-color: #000

    .subtext
        color: #666666

    &.dark
        .custom-select
            background: #222222 !important
            color: #bdbdbd !important
        .custom-select
            border: 1px solid #BDBDBD !important
            &:hover, &:active, &:focus
                border: 1px solid #f2f2f2 !important
                color: #f2f2f2 !important
            &.disabled
                border: 1px solid #686868 !important
                color: #686868 !important
        .ui.checkbox .box:before, .ui.checkbox label:before
            background: unset !important
            border: 1px solid #BDBDBD
            border-color: #BDBDBD
        .ui.checkbox .box:hover, .ui.checkbox label:hover, .ui .checked .checkbox input:focus
            &:before
                border: 1px solid #f2f2f2 !important
        .ui.checkbox input:checked:focus~label:before
            border-color: #f2f2f2 !important
        .ui.checkbox input:checked~label:after
            color: #fff
